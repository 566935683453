import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ChangeVerificationComponent } from './change-verification.component';
import { MarketplaceService } from '../../_services/marketplace/marketplace.service';

@Injectable({
  providedIn: 'root',
})
export class ChangeVerificationService {
  constructor(
    private dialog: MatDialog,
    private marketplaceService: MarketplaceService
  ) {}

  showVerificationAlert(matchWord, description, active, pageId) {
    const dialogRef = this.dialog.open(ChangeVerificationComponent, {
      data: {
        matchWord: matchWord,
        description: description,
        active: active,
        pageId: pageId,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.marketplaceService.getMarketplacePages(
        this.marketplaceService.marketplacePageId,
        1
      );
    });
  }
}
