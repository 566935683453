import { Component, OnInit, HostListener } from '@angular/core';
import { MarketplaceUserValidators } from 'src/app/shared/validators/marketplace-user';
import { SignerMenuService } from '../signer-menu.service';
import { Router } from '@angular/router';
import { MarketplaceUser } from 'src/app/shared/interfaces/marketplace-user';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MarketplaceService } from 'src/app/shared/_services/marketplace/marketplace.service';
import { GetAddressByCepService } from 'src/app/shared/_services/getAddressByCep/get-address-by-cep.service';
import { AlertService } from 'src/app/shared/dialogs/alert/alert.service';

@Component({
  selector: 'app-owner',
  templateUrl: './owner.component.html',
  styleUrls: ['./owner.component.scss'],
})
export class OwnerComponent implements OnInit {
  public marketplaceUser: MarketplaceUser = {};
  public innerWidth;
  public opened;
  public userEmailCheck = '';
  public hasUser = false;
  public isCheck = false;
  public messageVerify = '';

  constructor(
    public marketplaceUserVal: MarketplaceUserValidators,
    private signerMenuService: SignerMenuService,
    private router: Router,
    public snackbar: MatSnackBar,
    public marketplaceService: MarketplaceService,
    private getAddressService: GetAddressByCepService,
    public alertService: AlertService
  ) {
    this.marketplaceUser.address = {};
    this.innerWidth = window.innerWidth;
    if (window.innerWidth > 950) {
      this.opened = true;
    } else {
      this.opened = false;
    }
  }

  ngOnInit(): void {
    this.marketplaceUserVal.nameFormControl.reset();
    this.marketplaceUserVal.cpfFormControl.reset();
    this.marketplaceUserVal.userPhoneFormControl.reset();
    this.marketplaceUserVal.emailFormControl.reset();
    this.marketplaceUserVal.passwordFormControl.reset();
    this.marketplaceUserVal.birthDateFormControl.reset();
    this.marketplaceUserVal.postalCodeFormControl.reset();
    this.marketplaceUserVal.streetFormControl.reset();
    this.marketplaceUserVal.numberFormControl.reset();
    this.marketplaceUserVal.districtFormControl.reset();
    this.marketplaceUserVal.cityFormControl.reset();
    this.marketplaceUserVal.stateFormControl.reset();
    this.marketplaceUserVal.emailFormControl.enable();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (window.innerWidth > 950) {
      this.opened = true;
    } else {
      this.opened = false;
    }
    console.log(this.opened);
    // this.innerWidth = window.innerWidth;
  }

  getAddress() {
    this.getAddressService
      .getAddressByCep(this.marketplaceUser.address.postalCode)
      .then((res: any) => {
        res.json().then((res) => {
          this.marketplaceUser.address.street = res.logradouro;
          this.marketplaceUser.address.city = res.localidade;
          this.marketplaceUser.address.district = res.bairro;
          this.marketplaceUser.address.state = this.getAddressService.ufToState(
            res.uf
          );
        });
      });
  }

  previousForm() {
    for (let menuOption of this.signerMenuService.menuOptions) {
      if (menuOption.navigate != 'signer/marketplace') {
        menuOption.selected = false;
      } else {
        menuOption.selected = true;
      }
    }
    this.router.navigate(['signer/marketplace']);
  }

  next() {
    if(!this.hasUser){ 
      if(this.marketplaceUserVal.emailFormControl.invalid && this.isCheck == false){
        this.snackbar.open('Complete os campos corretamente', 'Fechar', {
          duration: 3000,
        });
      }else if (
        this.marketplaceUserVal.nameFormControl.invalid ||
        this.marketplaceUserVal.cpfFormControl.invalid ||
        this.marketplaceUserVal.userPhoneFormControl.invalid ||
        this.marketplaceUserVal.emailFormControl.invalid ||
        this.marketplaceUserVal.passwordFormControl.invalid ||
        this.marketplaceUserVal.birthDateFormControl.invalid ||
        this.marketplaceUserVal.postalCodeFormControl.invalid ||
        this.marketplaceUserVal.streetFormControl.invalid ||
        this.marketplaceUserVal.numberFormControl.invalid ||
        this.marketplaceUserVal.districtFormControl.invalid ||
        this.marketplaceUserVal.cityFormControl.invalid ||
        this.marketplaceUserVal.stateFormControl.invalid
      ) {
        if(this.isCheck){
          this.snackbar.open('Complete os campos corretamente', 'Fechar', {
            duration: 3000,
          });

        }
      } else {
        for (let menuOption of this.signerMenuService.menuOptions) {
          if (menuOption.navigate != 'signer/check') {
            menuOption.selected = false;
          } else {
            menuOption.selected = true;
          }
        }
        this.marketplaceService.newMarketplaceUser = this.marketplaceUser;
        this.marketplaceService.marketplaceWithUserExists = false;
        this.router.navigate(['signer/check']);
      }
    } else {
      if(this.marketplaceUserVal.emailFormControl.invalid ||
        this.marketplaceUserVal.nameFormControl.invalid ||
        this.marketplaceUserVal.userPhoneFormControl.invalid) {
          console.log(
            this.marketplaceUserVal.emailFormControl.invalid,
            this.marketplaceUserVal.nameFormControl.invalid,
            this.marketplaceUserVal.userPhoneFormControl.invalid
          );
          this.snackbar.open('Complete os campos corretamente', 'Fechar', {
            duration: 3000,
          });
        } else {
          for (let menuOption of this.signerMenuService.menuOptions) {
            if (menuOption.navigate != 'signer/check') {
              menuOption.selected = false;
            } else {
              menuOption.selected = true;
            }
          }
          this.marketplaceService.newMarketplaceUser = this.marketplaceUser;
          console.log("newMarketplaceUser", this.marketplaceService.newMarketplaceUser);
          this.marketplaceService.marketplaceWithUserExists = true;
          this.router.navigate(['signer/check']);
        }
    }
  }

  userEmailVerify(){
    if(this.marketplaceUserVal.emailFormControl.valid){      
      this.marketplaceService.checkUser(this.userEmailCheck).subscribe((res: any) => {
        if(res.exists) {
          this.hasUser = true;
          this.isCheck = true;
          this.marketplaceUser.name = res.name;
          this.marketplaceUser.phone = res.phone;
          this.marketplaceUser.cpf = res.cpf;
          this.marketplaceUser.birthDate = res.birthDate;
          this.marketplaceUser.address.postalCode = res.address.postalCode;
          this.marketplaceUser.address.street = res.address.street;
          this.marketplaceUser.address.number = res.address.number;
          this.marketplaceUser.address.district = res.address.district;
          this.marketplaceUser.address.state = res.address.state;
          this.marketplaceUser.address.city = res.address.city;
          this.marketplaceUserVal.emailFormControl.disable();
          this.marketplaceUserVal.nameFormControl.disable();
          this.marketplaceUserVal.userPhoneFormControl.disable();
          this.marketplaceUserVal.cpfFormControl.disable();
          this.marketplaceUserVal.birthDateFormControl.disable();
          this.marketplaceUserVal.postalCodeFormControl.disable();
          this.marketplaceUserVal.streetFormControl.disable();
          this.marketplaceUserVal.numberFormControl.disable();
          this.marketplaceUserVal.districtFormControl.disable();
          this.marketplaceUserVal.stateFormControl.disable();
          this.marketplaceUserVal.cityFormControl.disable();
          this.messageVerify = "Usuário encontrado";
        }else {
          this.isCheck = true;
          this.hasUser = false;
          this.messageVerify = "Usuário não existe, preencha o formulário";
          this.marketplaceUserVal.emailFormControl.disable();
          this.marketplaceUserVal.nameFormControl.enable();
        }
      },
      err => {
        console.log(err);
        this.alertService.showAlert('Erro', 'Erro ao realizar operação');
      });
    }
  }

  clearForm() {
    this.marketplaceUserVal.emailFormControl.enable();
    this.marketplaceUserVal.nameFormControl.enable();
    this.marketplaceUserVal.userPhoneFormControl.enable();
    this.marketplaceUserVal.emailFormControl.reset();
    this.marketplaceUserVal.nameFormControl.reset();
    this.marketplaceUserVal.userPhoneFormControl.reset();
    this.userEmailCheck = '';
    this.messageVerify = '';
    this.isCheck = false;
  }
}
