import { FormControl, Validators, FormGroup } from '@angular/forms';

export class MarketplaceValidators {
  nameFormControl = new FormControl('', [Validators.required]);

  nameIdFormControl = new FormControl('', [Validators.required]);

  phoneFormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(10),
    Validators.maxLength(11),
  ]);

  clientDomainFormControl = new FormControl('', [
    Validators.required,
  ])

  emailFormControl = new FormControl('', [
    Validators.email
  ]);

  urlFormControl = new FormControl('', [
    Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')
  ]);

  primaryColorFormControl = new FormControl('', [
    Validators.pattern('^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$'),
  ]);

  secondaryColorFormControl = new FormControl('', [
    Validators.pattern('^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$'),
  ]);

  public getClientDomainErrorMessage() {
      return this.clientDomainFormControl.hasError('required')
        ? 'Este campo é obrigatório'
        : '';
  }

  public getNameIdErrorMessage() {
    return this.nameFormControl.hasError('required')
      ? 'Este campo é obrigatório'
      : '';
  }

  public getNameErrorMessage() {
    return this.nameFormControl.hasError('required')
      ? 'Este campo é obrigatório'
      : '';
  }

  public getPhoneErrorMessage() {
    return this.phoneFormControl.hasError('required')
      ? 'Este campo é obrigatório'
      : this.phoneFormControl.hasError('minlength')
      ? 'Há números faltando'
      : this.phoneFormControl.hasError('maxlength')
      ? 'Há números demais'
      : '';
  }

  public getPrimaryColorErrorMessage() {
    return this.primaryColorFormControl.hasError('pattern')
      ? 'Este não é um hexadecimal válido'
      : '';
  }

  public getSecondaryColorErrorMessage() {
    return this.secondaryColorFormControl.hasError('pattern')
      ? 'Este não é um hexadecimal válido'
      : '';
  }
}
