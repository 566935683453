import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { MarketplaceAuthService } from '../../_services/marketplace-auth/marketplace-auth.service';

@Injectable({
  providedIn: "root"
})
export class MarketplaceGuard implements CanActivate {
  constructor(public auth: MarketplaceAuthService, private router: Router) {}

  canActivate(): Promise<boolean> {
    this.auth.checkToken().then(
      (res) => {
        if(!res){
          this.router.navigate(['login']);
        }
      }
    )
    return this.auth.checkToken();
  }
}