<div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="15px" class="main">
  <button class="fab-add-ad" mat-mini-fab matTooltip="Voltar ao menu" (click)="backToHome()">
    <mat-icon style="font-size: 23px; padding-bottom: 3px;">reply</mat-icon>
  </button>

  <div fxLayoutAlign="end end">
    <div class="avatar-circle" fxLayout="column" fxLayoutAlign="center center">
      <img style="height: 100%" [src]="marketplaceService.image">
    </div>
    <button (click)="fileInput.click()" class="upload-button" mat-mini-fab>
      <mat-icon>insert_photo</mat-icon>
    </button>
    <input hidden type="file" accept="image/png,image/jpeg" (change)="fileChangeEvent($event)" #fileInput id="file">
  </div>


  <div fxLayout="column" fxLayoutAlign="center center" class="form-div">
    <form class="form">
      <mat-form-field class="field">
        <mat-label class="label">Nome do Marketplace</mat-label>
        <input matInput class="field-line" name="name" [(ngModel)]="marketplace.name"
          [formControl]="marketplaceVal.nameFormControl">
        <mat-error *ngIf="marketplaceVal.nameFormControl.invalid">{{marketplaceVal.getNameErrorMessage()}}
        </mat-error>
      </mat-form-field>

      <mat-form-field class="field">
        <mat-label class="label">DDD + Telefone</mat-label>
        <input matInput class="field-line" name="phone" mask="(00) 00000-0000" [(ngModel)]="marketplace.phone"
          [formControl]="marketplaceVal.phoneFormControl">
        <mat-error *ngIf="marketplaceVal.phoneFormControl.invalid">{{marketplaceVal.getPhoneErrorMessage()}}</mat-error>
      </mat-form-field>

      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
        <span style="font-size: 130%; color: #003f63; padding-bottom: 10px!important">app.anota.ai/marketplace/</span>
        <mat-form-field class=" field">
          <mat-label class="label">Url Anota AI</mat-label>
          <input matInput class="field-line" name="name" [(ngModel)]="marketplace.name_id"
            [formControl]="marketplaceVal.nameIdFormControl">
          <mat-error *ngIf="marketplaceVal.nameIdFormControl.invalid">{{marketplaceVal.getNameIdErrorMessage()}}
          </mat-error>
        </mat-form-field>
      </div>
      <br>
      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="25px">
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15px">
          <mat-form-field class=" field">
            <mat-label class="label">Cor Primária</mat-label>
            <input matInput type="color" style="width: 22px;" name="name" [(ngModel)]="marketplace.colors.primary"
              [formControl]="marketplaceVal.primaryColorFormControl"> {{marketplaceVal.primaryColorFormControl.value}}
            <mat-error *ngIf="marketplaceVal.primaryColorFormControl.invalid">
              {{marketplaceVal.getPrimaryColorErrorMessage()}}
            </mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="2px">
          <mat-form-field class=" field">
            <mat-label class="label">Cor Secundária</mat-label>
            <input matInput type="color" style="width: 22px;" name="name" [(ngModel)]="marketplace.colors.secondary"
              [formControl]="marketplaceVal.secondaryColorFormControl"> {{marketplaceVal.secondaryColorFormControl.value}}
            <mat-error *ngIf="marketplaceVal.secondaryColorFormControl.invalid">
              {{marketplaceVal.getSecondaryColorErrorMessage()}}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <br>
      <div fxLayout="row" fxLayoutAlign="center center">
        <mat-slide-toggle name="active" [(ngModel)]="marketplace.active">
          {{marketplace.active? 'Ativo!' : 'Inativo!'}}
        </mat-slide-toggle>
      </div>
      <br>
      <div fxLayout="column" fxLayoutAlign="center center">
        <mat-slide-toggle name="selectTestMarketplace" [(ngModel)]="marketplace.production_test">
        {{marketplace.production_test? 'Marketplace Teste' : 'Marketplace Normal'}}
        </mat-slide-toggle>
          <span class="warn-test-marketplace">Após selecionar está opção, o marketplace sera identificado como um marketplace de teste.
          </span>
          <span class="warn-test-marketplace">
           Caso você querira mudar isso novamente só ir nas configurações do marketplace e mudar.
          </span>
      </div>
    </form>
  </div>

  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="25px" style="padding-bottom: 25px;">
    <button mat-stroked-button class="next-button" style="color: #003f63" (click)="cancel()">Cancelar</button>
    <button mat-flat-button style="background-color: #003f63; color: white" (click)="next()">Próximo</button>
  </div>
</div>
