import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MarketplaceService } from '../../_services/marketplace/marketplace.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-change-verification',
  templateUrl: './change-verification.component.html',
  styleUrls: ['./change-verification.component.scss'],
})
export class ChangeVerificationComponent implements OnInit {
  public description;
  public tryWord;
  public active;
  public matchWord;
  public pageId;

  constructor(
    private dialog: MatDialogRef<ChangeVerificationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private marketplaceService: MarketplaceService,
    private snackbar: MatSnackBar
  ) {
    this.matchWord = data.matchWord;
    this.description = data.description;
    this.active = data.active;
    this.pageId = data.pageId;
  }

  ngOnInit(): void {}

  cancel() {
    this.dialog.close();
  }

  submit() {
    this.marketplaceService.updatePage(this.pageId, this.active).subscribe(
      (res: any) => {
        if (res.success) {
          this.marketplaceService.getMarketplacePages(
            this.marketplaceService.marketplacePageId,
            1
          );
        } else {
          this.snackbar.open('Erro ao alterar o status da página', 'Fechar', {
            duration: 3000,
          });
        }
      },
      (err) => {
        this.snackbar.open('Erro ao alterar o status da página', 'Fechar', {
          duration: 3000,
        });
      }
    );
    this.dialog.close();
  }
}
