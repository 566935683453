import { Component, OnInit, HostListener } from '@angular/core';
import { MarketplaceAuthService } from 'src/app/shared/_services/marketplace-auth/marketplace-auth.service';
import { MarketplaceService } from 'src/app/shared/_services/marketplace/marketplace.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { Marketplace } from 'src/app/shared/interfaces/marketplace';
import { AlertService } from 'src/app/shared/dialogs/alert/alert.service';
import { makeBindingParser } from '@angular/compiler';
import { ObjectID } from 'mongodb';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
  public searchTerm;
  public filterStatus = null;
  public innerWidth = 1000;
  marketplaces: Marketplace[] = [];
  page: number = 0;

  constructor(
    private authService: MarketplaceAuthService,
    private router: Router,
    private snackbar: MatSnackBar,
    private alertService: AlertService,
    public marketplaceService: MarketplaceService) {

    this.innerWidth = window.innerWidth;
    this.getMarketplaces();
  }
  
  ngOnInit(): void {
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['login']);
    this.snackbar.open('Logout realizado com sucesso!', 'Fechar', {
      duration: 3000,
    });
  }

  searchMarketplaces(searchTerm) {
      this.marketplaceService.marketplaces = this.marketplaces.filter(
        (m) => {
          return (
            m.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 &&
            (m.active == this.filterStatus || this.filterStatus == null)
          );
        }
      );
  }

  filterMarketplacesByStatus(active) {
    if (active == null) {
      this.marketplaceService.marketplaces = this.marketplaceService.searchingMarketplaces;
    } else {
      this.marketplaceService.marketplaces = this.marketplaceService.searchingMarketplaces.filter(
        (e) => {
          return e.active == active;
        }
      );
    }
  }

  addMarketplace() {
    this.router.navigate(['signer']);
  }

  showDetails(marketplaceId: ObjectID) {
    this.marketplaceService.setMarketplaceId(marketplaceId)
    this.router.navigate(['/marketplace-details/' + marketplaceId]);
  }

  getMarketplaces() {
    this.marketplaceService.getMarketplaces(++this.page)
      .subscribe(
        (res: any) => {
          if (res.success) {
            if (res.info && res.info.docs) {
              this.marketplaces = this.marketplaces.concat(res.info.docs as Marketplace[]);
              this.marketplaceService.marketplaces = this.marketplaces;
            } 
          } else {
            this.alertService.showAlert(
              'Erro',
              res.message || 'Ocorreu um erro ao tentar buscar os marketplaces.'
            );
          }
        },
        err => console.log(err)
    );
  }

  onScroll() {
    this.getMarketplaces();
  }
}
