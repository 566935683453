<div *ngIf="innerWidth>950" fxLayout="column" fxLayoutAlign="center center" fxFill
  style="padding: 30px 30px;background-color:#003f63">
  <div fxLayout="row" fxLayoutAlign="center center" fxFill fxFlex>
    <div fxLayout="column" fxLayoutAlign="center center" fxFlex="55%" fxFill style="overflow: hidden">
      <img style="height: 100%;" src="../../../assets/22022.jpg">
    </div>
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="15px" fxFlex="45%" fxFill
      style="background-color:white; padding:20px;">
      <img style="width: 50%; max-height: 35%;" src="../../../assets/marketplace-logo.svg">
      <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px" class="form">
        <mat-form-field class="form-field">
          <mat-label>Login</mat-label>
          <input matInput [(ngModel)]="user.email" [formControl]="credentialsVal.emailFormControl">
          <mat-error *ngIf="credentialsVal.emailFormControl">{{credentialsVal.getEmailErrorMessage()}}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="form-field">
          <mat-label>Senha</mat-label>
          <input matInput type="password" [(ngModel)]="user.password"
            [formControl]="credentialsVal.passwordFormControl">
          <mat-error *ngIf="credentialsVal.passwordFormControl">{{credentialsVal.getPasswordErrorMessage()}}
          </mat-error>
        </mat-form-field>
      </div>
      <button mat-flat-button flex="33" class="sign-in-button" (click)='sign()'>Entrar</button>
      <button mat-button style="padding: 5px; background-color: rgba(245, 244, 244, 0.868); width: 175px;" (click)="loginGoogle()">
        <mat-icon style="width: 25px; height:30px; margin-right: 3px;" svgIcon="logo"></mat-icon>
        <span style="font-size: 20px;">Login Google</span>
      </button>
      <!-- <button mat-button class="login-help-button">PROBLEMAS PARA FAZER LOGIN?</button> -->
    </div>
  </div>
</div>
<div *ngIf="innerWidth<=950" fxLayout="column" fxLayoutAlign="center center" fxFill>
  <!-- <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="15px" style="background-color:white;"> -->
  <img style="width: 50%;" src="../../../assets/marketplace-logo.svg">
  <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px" class="form">
    <mat-form-field class="form-field">
      <mat-label>Login</mat-label>
      <input matInput [(ngModel)]="user.email" [formControl]="credentialsVal.emailFormControl">
      <mat-error *ngIf="credentialsVal.emailFormControl">{{credentialsVal.getEmailErrorMessage()}}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="form-field">
      <mat-label>Senha</mat-label>
      <input matInput type="password" [(ngModel)]="user.password" [formControl]="credentialsVal.passwordFormControl">
      <mat-error *ngIf="credentialsVal.passwordFormControl">{{credentialsVal.getPasswordErrorMessage()}}
      </mat-error>
    </mat-form-field>
  </div>
  <br>
  <button mat-flat-button flex="33" class="sign-in-button" (click)='sign()'>Entrar</button>
  <br>
  <button mat-button style="padding: 5px; background-color: rgba(245, 244, 244, 0.868); width: 175px;" (click)="loginGoogle()">
    <mat-icon style="width: 25px; height:30px; margin-right: 3px;" svgIcon="logo"></mat-icon>
    <span style="font-size: 20px;">Login Google</span>
  </button>
  <!-- <button mat-button class="login-help-button">PROBLEMAS PARA FAZER LOGIN?</button> -->
  <!-- </div> -->
</div>
