import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { SignerMenuService } from './signer-menu.service';

@Component({
  selector: 'app-signer',
  templateUrl: './signer.component.html',
  styleUrls: ['./signer.component.scss'],
})
export class SignerComponent implements OnInit {
  public opened;

  constructor(
    private router: Router,
    public signerMenuService: SignerMenuService
  ) {
    if (window.innerWidth > 950) {
      this.opened = true;
    } else {
      this.opened = false;
    }
  }

  ngOnInit(): void {}

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (window.innerWidth > 950) {
      this.opened = true;
    } else {
      this.opened = false;
    }
    console.log(this.opened);
    // this.innerWidth = window.innerWidth;
  }

  navigate(option) {
    for (let menuOption of this.signerMenuService.menuOptions) {
      menuOption.selected = false;
    }
    option.selected = true;
    console.log(option);
    this.router.navigate([option.navigate]);
  }
}
