<div class="infinite-scroll-container" infiniteScroll [infiniteScrollDistance]="1"
  [infiniteScrollThrottle]="50" [scrollWindow]="false" (scrolled)="onScroll()">
  
  <mat-toolbar class="toolbar">
    <img src="../../../assets/marketplace-logo-horizontal.svg" class="logo">
    <span class="example-spacer"></span>
    <button mat-icon-button (click)="logout()">
      <mat-icon style="color: #003f63">exit_to_app</mat-icon>
    </button>
  </mat-toolbar>

  <div class="searchbar" *ngIf="innerWidth > 950">
    <div>
      <form class="searchbar-form">
        <input #search type="text" placeholder="&#xF002;" style="font-family:Arial, FontAwesome" name="search"
          [(ngModel)]="searchTerm" (input)="searchMarketplaces(searchTerm)">
      </form>
      <button class="searchbar-button" type="submit" (click)="searchMarketplaces(searchTerm)">Buscar</button>
    </div>
    <div>
      <mat-form-field class="status-filter">
        <mat-icon matPrefix *ngIf="filterStatus === null" matTooltip="Atividade">panorama_fish_eye</mat-icon>
        <mat-icon matPrefix *ngIf="filterStatus === true" matTooltip="Ativo" style="color: #81D977;">lens</mat-icon>
        <mat-icon matPrefix *ngIf="filterStatus === false" matTooltip="Inativo" style="color: #B3312E;">lens</mat-icon>
        <mat-select [(ngModel)]="filterStatus" (selectionChange)="filterMarketplacesByStatus(filterStatus)">
          <mat-option [value]="null">
            <mat-icon matPrefix>panorama_fish_eye</mat-icon>
          </mat-option>
          <mat-option [value]="true">
            <mat-icon matPrefix style="color: #81D977;">lens</mat-icon>Ativo
          </mat-option>
          <mat-option [value]="false">
            <mat-icon matPrefix style="color: #B3312E;">lens</mat-icon>Inativo
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="panels" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="30px">
    <mat-list class="list">
      <mat-list-item *ngFor="let m of marketplaceService.marketplaces" (click)="showDetails(m._id)">
        <img mat-list-avatar style="border:2px solid #2F6180" [src]="m.image || '../../../assets/fastmeal-icon.svg'">
        <div mat-line>{{m.name}}</div>
        <button mat-icon-button matTooltip="Ver páginas associadas">
          <mat-icon>visibility</mat-icon>
        </button>
        <button mat-icon-button [matTooltip]="m.active ? 'Marketplace ativo' : 'Marketplace inativo'">
          <mat-icon [ngStyle]="{'color': m.active ? '#81D977' : '#B3312E'}">lens</mat-icon>
        </button>
        <mat-divider></mat-divider>
      </mat-list-item>
    </mat-list>
  </div>
  <div>
    <button class="fab-add-establishments" mat-fab (click)="addMarketplace()">
      <mat-icon>add</mat-icon>
    </button>
  </div>
</div>
