<div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="8px" fxFill style="padding: 20px 30px">

  <img src='../../../../assets/logo-robot.svg' height="100px">

  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px" style="width: 100%;">
    <mat-form-field class="field">
      <mat-label class="label">Id da Página</mat-label>
      <input matInput class="field-line" name="id" [(ngModel)]="pageId">
    </mat-form-field>

    <mat-slide-toggle name="active" [(ngModel)]="pageActive" matTooltip="Status da página">
    </mat-slide-toggle>
  </div>


  <button mat-button style="background-color: #003f63; color: white" (click)="linkPage()">
    Adicionar Página
  </button>
</div>
