import { CpfCnpjValidator } from "src/app/shared/validators/cpf-cnpj.validator";
import { FormControl, Validators, FormGroup, AbstractControl } from '@angular/forms';

export class MarketplaceUserValidators {
  nameFormControl = new FormControl('', [
    Validators.required,
    Validators.pattern('[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$')
  ]);

  cpfFormControl = new FormControl('', [
    Validators.required,
    CpfCnpjValidator.validCpfCnpj()
  ]);

  userPhoneFormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(10),
    Validators.maxLength(11),
  ]);

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  usernameFormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(6),
    Validators.maxLength(16),
    Validators.pattern('^[a-z0-9_-]+$'),
  ]);

  passwordFormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(6),
    Validators.maxLength(16),
  ]);

  birthDateFormControl = new FormControl('', [
    Validators.required,
    Validators.maxLength(10)
  ]);

  postalCodeFormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(8),
    Validators.maxLength(8),
  ]);

  streetFormControl = new FormControl('', [Validators.required]);

  numberFormControl = new FormControl('', [Validators.required]);

  districtFormControl = new FormControl('', [Validators.required]);

  cityFormControl = new FormControl('', [Validators.required]);

  stateFormControl = new FormControl('', [Validators.required]);

  public getFirstNameErrorMessage() {
    return this.nameFormControl.hasError('required')
      ? 'Este campo é obrigatório'
      : this.nameFormControl.hasError('pattern')
      ? 'Nome inválido'
      : '';
  }

  public getUserPhoneErrorMessage() {
    return this.userPhoneFormControl.hasError('required')
      ? 'Este campo é obrigatório'
      : this.userPhoneFormControl.hasError('minlength')
      ? 'Há números faltando'
      : this.userPhoneFormControl.hasError('maxlength')
      ? 'Há números demais'
      : '';
  }

  public getEmailErrorMessage() {
    return this.emailFormControl.hasError('required')
      ? 'Este campo é obrigatório'
      : this.emailFormControl.hasError('email')
      ? 'Email inválido'
      : '';
  }

  public getUsernameErrorMessage() {
    return this.usernameFormControl.hasError('required')
      ? 'Este campo é obrigatório'
      : this.usernameFormControl.hasError('minlength')
      ? 'Mínimo 6 caracteres'
      : this.usernameFormControl.hasError('maxlength')
      ? 'Máximo 16 caracteres'
      : this.usernameFormControl.hasError('pattern')
      ? 'Este campo só aceita letras minúsculas'
      : '';
  }

  public getPasswordErrorMessage() {
    return this.passwordFormControl.hasError('required')
      ? 'Este campo é obrigatório'
      : this.passwordFormControl.hasError('minlength')
      ? 'Mínimo 6 caracteres'
      : this.passwordFormControl.hasError('maxlength')
      ? 'Máximo 16 caracteres'
      : '';
  }

  public getCpfErrorMessage() {
    return this.cpfFormControl.hasError('required')
      ? 'Este campo é obrigatório'
      : this.cpfFormControl.hasError('minlength')
      ? 'Há números faltando'
      : this.cpfFormControl.hasError('maxlength')
      ? 'Há números demais'
      : 'CPF inválido';
  }

  public getBirthDateErrorMessage() {
    return this.birthDateFormControl.hasError('required')
    ? 'Este campo é obrigatório'
    : this.birthDateFormControl.hasError('maxlength')
    ? 'Data inválida'
    : '';
    
  }

  public getPostalCodeErrorMessage() {
    return this.postalCodeFormControl.hasError('required')
      ? 'Este campo é obrigatório'
      : this.postalCodeFormControl.hasError('minlength')
      ? 'Há números faltando'
      : this.postalCodeFormControl.hasError('maxlength')
      ? 'Há números demais'
      : '';
  }

  public getStreetErrorMessage() {
    return this.postalCodeFormControl.hasError('required')
      ? 'Este campo é obrigatório'
      : '';
  }

  public getNumberErrorMessage() {
    return this.numberFormControl.hasError('required') ? '*' : '';
  }

  public getDistrictErrorMessage() {
    return this.districtFormControl.hasError('required')
      ? 'Este campo é obrigatório'
      : '';
  }

  public getCityErrorMessage() {
    return this.cityFormControl.hasError('required')
      ? 'Este campo é obrigatório'
      : '';
  }

  public getStateErrorMessage() {
    return this.stateFormControl.hasError('required')
      ? 'Este campo é obrigatório'
      : '';
  }
}
