import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { AlertService } from '../alert/alert.service';
import { MarketplaceService } from '../../_services/marketplace/marketplace.service';

@Component({
  selector: 'app-img-cropper',
  templateUrl: './img-cropper.component.html',
  styleUrls: ['./img-cropper.component.scss'],
})
export class ImgCropperComponent implements OnInit {
  public spinner = true;
  public imgFile;
  public croppedImage;
  public imgSize;

  constructor(
    private dialog: MatDialogRef<ImgCropperComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private alertService: AlertService,
    private marketplaceService: MarketplaceService
  ) {
    this.imgFile = this.data.imgFile;
  }

  ngOnInit(): void {}

  imageCropped(event: ImageCroppedEvent) {
    console.log(event);
    this.croppedImage = event.base64;
  }

  imageLoaded() {
    console.log('loaded');
  }

  cropperReady() {
    console.log('read');
    this.spinner = false;
  }

  loadImageFailed() {
    this.alertService.showAlert('Erro', 'Falha ao carregar arquivo');
    this.dialog.close();
  }

  submit() {
    fetch(this.croppedImage)
      .then((res) => res.blob())
      .then((blob) => {
        this.marketplaceService.image = this.croppedImage;
        this.marketplaceService.imageFile = new File([blob], 'File_name', {
          type: 'image/png',
        });
        this.imgSize = this.imgFile.size;
      });
    this.dialog.close();
  }

  cancel() {
    this.dialog.close();
  }
}
