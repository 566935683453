import { Component, OnInit, Inject } from '@angular/core';
import { MarketplaceService } from '../../_services/marketplace/marketplace.service';
import { AlertService } from '../../dialogs/alert/alert.service';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheet,
} from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-link-page-bottom-sheet',
  templateUrl: './link-page-bottom-sheet.component.html',
  styleUrls: ['./link-page-bottom-sheet.component.scss'],
})
export class LinkPageBottomSheetComponent implements OnInit {
  private marketplaceId;
  public pageId;
  public pageActive;

  constructor(
    private marketplaceService: MarketplaceService,
    private alertService: AlertService,
    private bottomSheet: MatBottomSheet,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
  ) {
    this.pageActive = false;
    this.marketplaceId = data.marketplaceId;
  }

  ngOnInit(): void {}

  linkPage() {
    console.log({
      marketplace: this.marketplaceId,
      page: this.pageId,
      active: this.pageActive,
    });
    this.marketplaceService
      .linkMarketplacePage(this.marketplaceId, this.pageId, this.pageActive)
      .subscribe((res: any) => {
        if (res.success) {
          this.marketplaceService.getMarketplacePages(this.marketplaceId, 1);
          this.bottomSheet.dismiss(true);
        } else {
          this.alertService.showAlert('Erro', res.message);
        }
      });
  }
}
