<mat-toolbar class="toolbar">
  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
    <button mat-icon-button (click)="backToMarketplace()">
      <mat-icon style="color: #003f63">
        arrow_back</mat-icon>
    </button>
    <span style="color: #003f63">Shopping Partage - Anúncios</span>
  </div>
  <span class="example-spacer"></span>
  <button mat-icon-button>
    <mat-icon style="color: #003f63">exit_to_app</mat-icon>
  </button>
</mat-toolbar>

<div class="panels" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="30px">
  <mat-list class="list">
    <mat-list-item class="list-item" *ngFor="let ad of ads">
      <img style="border:2px solid #2F6180; width: 400px; height: 120px;" [src]="ad.image">
      <span class="spacer"></span>
      <!-- <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px"> -->
      <span (click)="goToUrl(ad.link)">{{ad.link}}</span>
      <span class="spacer"></span>
      <mat-slide-toggle name="active" [(ngModel)]="ad.active" (ngModelChange)="updateStatus(ad)">
      </mat-slide-toggle>
      <!-- </div> -->
      <mat-divider></mat-divider>
    </mat-list-item>
  </mat-list>
</div>
<button class="fab-add-ad" mat-fab (click)="createAd()">
  <mat-icon>add</mat-icon>
</button>
