<mat-sidenav-container class="sidenav-container">
  <mat-sidenav class="sidenav" mode="side" [opened]=opened>
    <div fxLayout="column" fxLayoutGap="15px">
      <br>
      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
        <img class="logo" src="../../assets/marketplace-logo-horizontal.svg">
      </div>

      <mat-list>
        <div *ngFor="let option of signerMenuService.menuOptions">
          <mat-list-item style="cursor:pointer;" [ngStyle]="{'color':option.selected ? '#0099FF' : '#2F6180' }">
            <mat-icon matListIcon>{{option.icon}}</mat-icon>
            <p matLine>{{option.title}}</p>
          </mat-list-item>
          <mat-divider></mat-divider>
        </div>
      </mat-list>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
