<mat-tab-group [disableRipple]="false">
    <mat-tab label="Edições">
      <div class="modal modal--hidden" id="modal">
        <div fxLayout="column" class="modal_contents" id="modal_contents">
          <div>
            <button mat-button style="color: '#2F6180';" (click)="verifyEvent(1)" id="btn-user">Editar Usuário</button>
            <!-- <button mat-button class="btn-tittle" id="btn-whats"(click)="verifyEvent(2)">Editar WhatsApp</button> -->
            <!-- <button mat-button class="btn-tittle" id="btn-bot"(click)="verifyEvent(3)">Editar Bot</button>  -->
          </div>
          <div class="modal-edit" id="uploadTab" *ngIf="editUser">
              <mat-list>
                <mat-list-item style="color: #2F6180;">
                  <mat-icon mat-list-icon matTooltip="Estabelecimento">important_devices</mat-icon>
                  <input matInput class="input-edit" name="namePlace" [(ngModel)]="marketplaceService.marketplaceUser.marketplace.name">
                </mat-list-item>
                <mat-list-item style="color: #2F6180;">
                  <mat-icon mat-list-icon matTooltip="Telefone">phone</mat-icon>
                  <input matInput class="input-edit" name="phonePlace" [(ngModel)]="marketplaceService.marketplaceUser.marketplace.phone">
                </mat-list-item>
                <mat-list-item style="color: #2F6180;">
                  <mat-icon mat-list-icon matTooltip="Name ID">language</mat-icon>
                  <input matInput class="input-edit" name="nomeId" [(ngModel)]="marketplaceService.marketplaceUser.marketplace.name_id">
                </mat-list-item>
                <mat-list-item style="color: #2F6180;">
                  <mat-icon mat-list-icon matTooltip="Nome">person</mat-icon>
                  <input matInput class="input-edit" name="namePerson" [(ngModel)]="marketplaceService.marketplaceUser.name">
                </mat-list-item>
                <mat-list-item style="color: #2F6180;">
                  <mat-icon mat-list-icon matTooltip="Usuario">account_circle</mat-icon>
                  <input matInput class="input-edit" name="namePlace" [(ngModel)]="marketplaceService.marketplaceUser.username">
                </mat-list-item>
                <mat-list-item style="color: #2F6180;">
                  <mat-icon mat-list-icon matTooltip="Contato">contact_phone</mat-icon>
                  <input matInput class="input-edit" name="phonePerson" [(ngModel)]="marketplaceService.marketplaceUser.phone">
                </mat-list-item>
                <mat-list-item style="color: #2F6180;">
                  <mat-icon mat-list-icon matTooltip="Email">mail</mat-icon>
                  <input matInput
                    class="input-edit"
                    name="emailPerson"
                    [(ngModel)]="marketplaceService.marketplaceUser.email"
                    [formControl]="marketplaceVal.emailFormControl"
                  >
                </mat-list-item>
                <mat-list-item style="color: #2F6180;">
                  <mat-icon mat-list-icon matTooltip="Nome do Bot">support_agent</mat-icon>
                  <input matInput class="input-edit" name="botName" [(ngModel)]="marketplaceService.marketplaceUser.marketplace.config_bot.bot_name">
                </mat-list-item>
                <mat-list-item style="color: #2F6180;">
                  <mat-icon mat-list-icon matTooltip="Seleção por localização">map</mat-icon>
                  <mat-slide-toggle name="selectLocation" [(ngModel)]="marketplaceService.marketplaceUser.marketplace.config_bot.select_using_location">
                  </mat-slide-toggle>
                </mat-list-item>
                <mat-error style="margin-left: 35px;" *ngIf="marketplaceVal.emailFormControl.invalid">Preencha o campo corretamente!</mat-error>

                <mat-list-item fxLayout="column" style="color: #2F6180;">
                  <mat-icon mat-list-icon matTooltip="Tipo do marketplace">info</mat-icon>
                  <mat-slide-toggle name="selectTestMarketplace" [(ngModel)]="marketplaceService.marketplaceUser.marketplace.production_test">
                    {{marketplaceService.marketplaceUser.marketplace?.production_test ?  'Teste' : 'Normal'}}
                  </mat-slide-toggle>
                </mat-list-item>
                <div fxLayout="column">
                  <span class="warn-test-marketplace">Após selecionar está opção, o marketplace sera identificado como um marketplace de teste.
                  </span>
                  <span class="warn-test-marketplace">
                   Caso você querira mudar isso novamente só ir nas configurações do marketplace e mudar.
                  </span>
                </div>
              </mat-list>
              <div fxLayout="row" fxLayoutAlign="center center">
                <button mat-button class="submitChangesBtn" (click)="submitEdit(0)">Salvar</button>
                <button mat-stroked-button class="cancelChangesBtn" (click)="closeEdit()">Cancelar</button>
              </div>
            </div>
            <div *ngIf="editWhats">
              <!-- <mat-list> !BOTÃO ENVIAR IMAGEM!
                <mat-list-item fxLayoutAlign="center center" style="color: #2F6180; cursor: pointer; margin-top: 6px;">
                  <div>
                    <label class="myFakeUploadButton" for="myFileInput">
                      <mat-icon mat-list-icon>photo</mat-icon>
                      {{file ? "Salvar nova foto" : "Editar Foto de Perfil"}}
                    </label>
                    <input *ngIf = "!file" type="file" aria-label="upload" id="myFileInput" (change)="uploadButton($event)">
                    <button *ngIf = "file" aria-label="upload" id="myFileInput" (click)="uploadFile()"></button>
                    <div class="selected-file" *ngIf = "file">
                      <span class="file-name">
                        {{file.name}}
                      </span>
                      <mat-icon class="trash" (click)="clearFile()">delete_outline</mat-icon>
                    </div>
                  </div>
                </mat-list-item>
              </mat-list> -->
              <mat-list>
                <mat-list-item style="color: #2F6180;">
                  <mat-icon mat-list-icon matTooltip="Endereço WhatsApp">home</mat-icon>
                  <input matInput class="input-edit" name="adressPlace" placeholder="Endereço" [(ngModel)]="marketplace_whatsApp.address">
                </mat-list-item>
                <mat-list-item style="color: #2F6180;">
                  <mat-icon mat-list-icon matTooltip="Descriçao WhatsApp">description</mat-icon>
                  <input matInput class="input-edit" name="descPlace" placeholder="Descrição" [(ngModel)]="marketplace_whatsApp.description">
                </mat-list-item>
                <mat-list-item style="color: #2F6180;">
                  <mat-icon mat-list-icon matTooltip="Email WhatsApp">email</mat-icon>
                  <input matInput
                    class="input-edit"
                    [(ngModel)]="marketplace_whatsApp.email"
                    [formControl]="marketplaceVal.emailFormControl"
                    placeholder="Email"
                  >
                </mat-list-item>
                <mat-error style="margin-left: 35px;" *ngIf="marketplaceVal.emailFormControl.invalid">Preencha o campo corretamente!</mat-error>
                <br>
                <mat-list-item style="color: #2F6180;">
                  <mat-icon mat-list-icon matTooltip="Vertical WhatsApp">account_circle</mat-icon>
                  <mat-form-field appearance="fill">
                    <mat-label>Vertical</mat-label>
                    <mat-select [(ngModel)]="marketplace_whatsApp.vertical">
                      <mat-option  *ngFor="let list of verticalList" [(value)]="list.value">{{ list.viewValue }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </mat-list-item>
                <mat-list-item style="color: #2F6180;">
                  <mat-icon mat-list-icon matTooltip="Sites WhatsApp">desktop_windows</mat-icon>
                  <input matInput
                    class="input-edit"
                    name="webPlace"
                    placeholder="Websites"
                    [(ngModel)]="marketplace_whatsApp.websites"
                    [formControl]="marketplaceVal.urlFormControl"
                  >
                </mat-list-item>
                <mat-error style="margin-left: 35px;" *ngIf="marketplaceVal.urlFormControl.invalid">Preencha o campo corretamente!</mat-error>
              </mat-list>
              <div fxLayoutAlign="center center">
                <button mat-button class="submitChangesBtn" (click)="submitEdit(1)">Salvar</button>
              </div>
              <br>
              <mat-list fxLayout="row">
                <mat-list-item style="color: #2F6180;">
                  <mat-icon mat-list-icon matTooltip="Url WhatsApp">link</mat-icon>
                  <input matInput
                    class="input-edit"
                    name="urlPlace"
                    placeholder="Url"
                    [(ngModel)]="marketplace_whatsApp.webhook_url"
                    [formControl]="marketplaceVal.urlFormControl"
                  >
                </mat-list-item>
                <div>
                  <button mat-button class="submitChangesBtn" (click)="submitEdit(2)">Salvar URL</button>
                </div>
              </mat-list>
              <mat-error style="margin-left: 35px;" *ngIf="marketplaceVal.urlFormControl.invalid">Preencha o campo corretamente!</mat-error>
            </div>
            <div *ngIf="editBot">
              <mat-list>
                <mat-list-item style="color: #2F6180;">
                  <span style="font-weight: bolder; margin-left: 8px;"> Ativar Listagem </span>
                  <mat-slide-toggle name="active-list" style="margin-left: 5px;"></mat-slide-toggle>
                </mat-list-item>
                <mat-list-item style="color: #2F6180;">
                  <mat-icon mat-list-icon matTooltip="Nome do Bot">ballot</mat-icon>
                  <input matInput class="input-edit" name="phonePlace">
                </mat-list-item>
              </mat-list>
              <div fxLayoutAlign="center center">
                <button mat-button class="submitChangesBtn" (click)="submitEdit()">Salvar Bot</button>
              </div>
            </div>
          </div>
      </div>
    </mat-tab>
    <mat-tab label="Domínio">
      <mat-list>
        <mat-list-item style="color: #2F6180;">
          <mat-icon mat-list-icon matTooltip="Título">title</mat-icon>
          <input matInput class="input-edit" name="title"
          disabled
          [placeholder]="title"
          [(ngModel)]="integrationBody.title"/>
        </mat-list-item>
        <mat-list-item style="color: #2F6180;">
          <mat-icon mat-list-icon matTooltip="URL do marketplace">link</mat-icon>
          <input
          matInput
          class="input-edit"
          name="clientMenuLink"
          [placeholder]="clientMenuLink"
          [(ngModel)]="integrationBody.clientMenuLink"/>
        </mat-list-item>
        <mat-list-item style="color: #2F6180;">
          <mat-icon mat-list-icon matTooltip="Domínio">domain</mat-icon>
          <input matInput class="input-edit" name="clientDomain"
          [placeholder]="clientDomain"
          [(ngModel)]="integrationBody.clientDomain"
          [formControl]="marketplaceVal.clientDomainFormControl"/>
        </mat-list-item>
        <mat-error style="margin-left: 35px; font-size: 11px; margin-top: -10px;" *ngIf="marketplaceVal.clientDomainFormControl.invalid">
         {{ marketplaceVal.getClientDomainErrorMessage() }}
        </mat-error>
        <mat-list-item style="color: #2F6180;">
          <mat-icon mat-list-icon matTooltip="Google Tag ManagerId">perm_identity</mat-icon>
          <input matInput class="input-edit" name="googleTagManagerId"
          [placeholder]="googleTagManagerId"
           [(ngModel)]="integrationBody.googleTagManagerId"/>
        </mat-list-item>
      </mat-list>
      <div fxLayout="row" fxLayoutAlign="center center">
        <button mat-button class="submitChangesBtn" [disabled]="marketplaceVal.clientDomainFormControl.invalid" (click)="submitEditIntegration()">Salvar</button>
        <button mat-stroked-button class="cancelChangesBtn" (click)="closeEdit()">Cancelar</button>
      </div>
    </mat-tab>
</mat-tab-group>


