import { Integration } from './../../interfaces/integrations';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Marketplace } from '../../interfaces/marketplace';
import { MarketplaceUser } from '../../interfaces/marketplace-user';
import { MarketplacePage } from '../../interfaces/marketplace-page';
import { AlertService } from '../../dialogs/alert/alert.service';
import { ObjectID } from 'mongodb';

@Injectable({
  providedIn: 'root',
})
export class MarketplaceService {
  public url = environment.urlAuth;
  public newMarketplace: Marketplace = {};
  public newMarketplaceUser: MarketplaceUser = {};
  public marketplaces: Array<Marketplace>;
  public searchingMarketplaces;
  public marketplaceUser: MarketplaceUser = {};
  public marketplacePages: Array<MarketplacePage>;
  public imageFile;
  public image = '../../../../assets/fastmeal-marketplace-icon.svg';
  public marketplacePageId;
  public marketplaceWithUserExists;
  marketplaceId: ObjectID;

  constructor(public http: HttpClient, public alertService: AlertService) {
    this.marketplaceUser.marketplace = {};
  }

  getMarketplaces(page: number) {
    return this.http.get(this.url + '/marketplace/?page=' + page);
  }

  setMarketplaceId(marketplaceId: ObjectID) {
    this.marketplaceId = marketplaceId;
  }

  getMarketplaceId(): ObjectID {
    return this.marketplaceId;
  }

  getMarketplaceDetail(marketplaceId: ObjectID | string) {
    this.http.get(this.url + '/marketplace/' + marketplaceId).subscribe(
      (res: any) => {
        if (res.success) {
          this.marketplaceUser = res.info;
          this.marketplaceUser.marketplace.token = res.token;
          this.marketplacePageId = marketplaceId;
        } else {
          this.alertService.showAlert(
            'Erro',
            'Não conseguimos resgatar este marketplace'
          );
        }
      },
      (err) => {
        this.alertService.showAlert('Erro', 'Erro ao realizar operação');
      }
    );
  }

  getMarketplacePages(marketplaceId: ObjectID | string, page: number) {
    return this.http
      .get(this.url + '/marketplace/pages/' + marketplaceId + '?page=' + page)
  }

  createMarketplace() {
    let marketplace = {
      name: this.newMarketplace.name,
      name_id: this.newMarketplace.name_id,
      phone: this.newMarketplace.phone,
      colors: {
        primary: this.newMarketplace.colors.primary,
        secondary: this.newMarketplace.colors.secondary,
      },
      production_test: this.newMarketplace.production_test
    };

    if(this.marketplaceWithUserExists) {
      let user = {
        name: this.newMarketplaceUser.name,
        cpf: this.newMarketplaceUser.cpf,
        email: this.newMarketplaceUser.email,
        userPhone: this.newMarketplaceUser.phone,
        birthDate: this.newMarketplaceUser.birthDate,
        address: {
          street: this.newMarketplaceUser.address.street,
          postalCode: this.newMarketplaceUser.address.postalCode,
          district: this.newMarketplaceUser.address.district,
          city: this.newMarketplaceUser.address.city,
          state: this.newMarketplaceUser.address.state,
          complement: this.newMarketplaceUser.address.complement,
          number: this.newMarketplaceUser.address.number,
        },
        username: this.newMarketplaceUser.username,
        password: this.newMarketplaceUser.password,
      }

      return this.http.post(
        this.url + '/marketplace/create', { marketplace, user });
    } else {
      let newUser = {
        name: this.newMarketplaceUser.name,
        cpf: this.newMarketplaceUser.cpf,
        email: this.newMarketplaceUser.email,
        userPhone: this.newMarketplaceUser.phone,
        birthDate: this.newMarketplaceUser.birthDate,
        address: {
          street: this.newMarketplaceUser.address.street,
          postalCode: this.newMarketplaceUser.address.postalCode,
          district: this.newMarketplaceUser.address.district,
          city: this.newMarketplaceUser.address.city,
          state: this.newMarketplaceUser.address.state,
          complement: this.newMarketplaceUser.address.complement,
          number: this.newMarketplaceUser.address.number,
        },
        username: this.newMarketplaceUser.username,
        password: this.newMarketplaceUser.password,
      }
      return this.http.post(
        this.url + '/marketplace/create', { marketplace, user: newUser });
    }

  }

  synchronize(page_id) {
    return this.http.post(this.url + '/page/synchronize', {
      page: page_id
    }).toPromise();
  }

  linkMarketplacePage(marketplaceId, page, pageActive) {
    return this.http.post(
      this.url + '/marketplace/link-marketplace-page/' + marketplaceId,
      { page, pageActive }
    );
  }

  updatePage(pageId, active) {
    return this.http.patch(this.url + '/marketplace/pages/' + pageId, { active });
  }

  updateMarketplace(marketplaceId, active) {
    return this.http.patch(this.url + '/marketplace/' + marketplaceId, {
      active: active,
    });
  }

  uploadImage(marketplace_id) {
    let url = environment.urlAuth + '/marketplace/logo';
    let imgFile = this.imageFile;
    const formData = new FormData();
    formData.append('image', imgFile);
    formData.append('marketplaceId', marketplace_id);
    return this.http.post(url, formData);
  }

  checkUser(email) {
    let url = environment.urlAuth + '/marketplace/checkuser';
    return this.http.post(url, { email });
  }

  finishEdit(body) {
    let url = environment.urlAuth + '/marketplace/update';
    return this.http.put(url, body);
  }

  WhatsPhotoEdit(marketplace_id, whatsFoto_official) {
    return this.http.post(`${environment.urlAuth}/marketplace/whatsapp-official/profile-picture/${marketplace_id}`, whatsFoto_official);
  }

  WhatsUserEdit(marketplace_id, whatsUser_official) {
    return this.http.post( `${environment.urlAuth}/marketplace/whatsapp-official/update-client/${marketplace_id}`, whatsUser_official);
  }

  WhatsUrlEdit(marketplace_id, whatsUrl_official) {
    return this.http.patch( `${environment.urlAuth}/marketplace/whatsapp-official/update-webhook/${marketplace_id}`, whatsUrl_official);
  }

  newIntegration(marketplaceId, body){
  return this.http.put(`${environment.urlAuth}/integrations/${marketplaceId}`, body)
  }
}
