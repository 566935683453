<!-- <div class="main" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="15px">
  <span>Ajustar Imagem</span>
  <div fxLayout="row" fxLayoutAlign="center center">
    <image-cropper class="cropper" containWithinAspectRatio="true" backgroundColor="#FFFFF" [imageChangedEvent]="imgFile" [maintainAspectRatio]="true" [aspectRatio]="1 / 1"
      format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()"
      (loadImageFailed)="loadImageFailed()"></image-cropper>
  </div>
  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15px">
    <button mat-flat-button flex="33" class="cancel-button" (click)="cancel()">Fechar</button>
    <button mat-flat-button flex="33" class="submit-button" (click)="submit()">Concluído</button>
  </div>
</div> -->

<div mat-dialog-title class="div-alert-title">
  <div fxLayout="row" fxLayoutAlign="center center">
    <img class="img" src="../../../../assets/logo-robot.svg">
    <div class="divider"></div>
    <b><span class="alert-title">Ajustar Imagem</span></b>
  </div>
</div>
<div mat-dialog-content class="div-alert-content">
  <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="25px">
    <image-cropper class="cropper" containWithinAspectRatio="true" backgroundColor="#FFFFF"
      [imageChangedEvent]="imgFile" [maintainAspectRatio]="true" [aspectRatio]="1 / 1" format="png"
      (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()"
      (loadImageFailed)="loadImageFailed()"></image-cropper>
    <div class="spinner" *ngIf="spinner" fxLayout="column" fxLayoutAlign="center center" class="spinner-div">
      <mat-spinner></mat-spinner>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15px">
      <button mat-flat-button flex="33" class="cancel-button" (click)="cancel()">Fechar</button>
      <button mat-flat-button flex="33" class="submit-button" (click)="submit()">Concluído</button>
    </div>
  </div>
  <br>
</div>
<div mat-dialog-actions></div>
