import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SignerMenuService } from '../signer-menu.service';
import { MarketplaceService } from 'src/app/shared/_services/marketplace/marketplace.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertService } from 'src/app/shared/dialogs/alert/alert.service';

@Component({
  selector: 'app-check',
  templateUrl: './check.component.html',
  styleUrls: ['./check.component.scss'],
})
export class CheckComponent implements OnInit {
  constructor(
    private signerMenuService: SignerMenuService,
    private router: Router,
    public marketplaceService: MarketplaceService,
    public snackbar: MatSnackBar,
    public alertService: AlertService
  ) {}

  previousForm() {
    for (let menuOption of this.signerMenuService.menuOptions) {
      if (menuOption.navigate != 'signer/owner') {
        menuOption.selected = false;
      } else {
        menuOption.selected = true;
      }
    }
    this.router.navigate(['signer/owner']);
  }

  submit() {
    this.marketplaceService.createMarketplace().subscribe(
      (res: any) => {
        if (res.success) {
          this.marketplaceService
            .uploadImage(res.marketplace_user)
            .subscribe((res: any) => {
              if (res.success) {
                this.snackbar.open('Marketplace criado com sucesso', 'Fechar', {
                  duration: 3000,
                });
                this.router.navigate(['marketplace-details/' + res.info._id]);
              } else {
                this.alertService.showAlert('Erro', res.message);
              }
            });
        } else {
          this.alertService.showAlert('Erro', res.message);
          this.router.navigate(['signer/marketplace']);
        }
      },
      (err) => {
        this.alertService.showAlert('Erro', 'Erro ao realizar operação');
        console.log(err)
      }
    );
  }

  ngOnInit(): void {}
}
