<div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="15px" class="main">

  <div class="form">
    <spam [class]="hasUser ? 'message-found' : 'message-not-found'" *ngIf="messageVerify !== ''">{{ messageVerify }}</spam>
    <div fxLayout="row" fxLayoutGap="25px" [ngStyle]="{'display': opened ? 'flex' : 'none' }" style="align-items: center;">
    <mat-form-field class="field">
      <mat-label class="label">Email</mat-label>
      <input matInput class="field-line" name="email" [(ngModel)]="marketplaceUser.email" (keyup)="userEmailCheck = $event.target.value"
        [formControl]="marketplaceUserVal.emailFormControl">
      <mat-error *ngIf="marketplaceUserVal.emailFormControl.invalid">
        {{marketplaceUserVal.getEmailErrorMessage()}}
      </mat-error>
    </mat-form-field>
    <button *ngIf="isCheck" mat-raised-button class="button-clear-check"
      (click)="clearForm()">
      Limpar e Verificar
    </button>
    </div>
    
    <div *ngIf="isCheck">
      <div *ngIf="hasUser">
        <mat-form-field class="field">
          <mat-label class="label">Nome</mat-label>
          <input matInput type="text" class="field-line" name="name" [(ngModel)]="marketplaceUser.name"
            [formControl]="marketplaceUserVal.nameFormControl">
          <mat-error *ngIf="marketplaceUserVal.nameFormControl.invalid">
            {{marketplaceUserVal.getFirstNameErrorMessage()}}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label class="label">DDD + Telefone</mat-label>
          <input matInput class="field-line" name="userPhone" mask="(00) 00000-0000" [(ngModel)]="marketplaceUser.userPhone"
            [formControl]="marketplaceUserVal.userPhoneFormControl">
          <mat-error *ngIf="marketplaceUserVal.userPhoneFormControl">{{marketplaceUserVal.getUserPhoneErrorMessage()}}
          </mat-error>
        </mat-form-field>
        <div fxLayout="row" fxLayoutGap="25px" [ngStyle]="{'display': opened ? 'flex' : 'none' }">
          <mat-form-field class="field">
            <mat-label class="label">CPF</mat-label>
            <input matInput class="field-line" name="cpf" mask="000.000.000-00" [(ngModel)]="marketplaceUser.cpf"
              [formControl]="marketplaceUserVal.cpfFormControl">
            <mat-error *ngIf="marketplaceUserVal.cpfFormControl.invalid">{{marketplaceUserVal.getCpfErrorMessage()}}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="field">
            <mat-label class="label">Data de Nascimento</mat-label>
            <input matInput class="field-line" type="date" name="birthDate" [(ngModel)]="marketplaceUser.birthDate"
              [formControl]="marketplaceUserVal.birthDateFormControl">
            <mat-error *ngIf="marketplaceUserVal.birthDateFormControl.invalid">
              {{marketplaceUserVal.getBirthDateErrorMessage()}}</mat-error>
          </mat-form-field>
        </div>
        <mat-form-field class="field">
          <mat-label class="label">DDD + Telefone</mat-label>
          <input matInput class="field-line" name="phone" mask="(00) 00000-0000" [(ngModel)]="marketplaceUser.phone"
            [formControl]="marketplaceUserVal.userPhoneFormControl">
          <mat-error *ngIf="marketplaceUserVal.userPhoneFormControl">{{marketplaceUserVal.getUserPhoneErrorMessage()}}
          </mat-error>
        </mat-form-field>
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
          <mat-form-field class="field">
            <mat-label class="label">CEP</mat-label>
            <input matInput class="field-line" name="address.postalCode" mask="00000-000"
              [(ngModel)]="marketplaceUser.address.postalCode" [formControl]="marketplaceUserVal.postalCodeFormControl">
            <mat-error *ngIf="marketplaceUserVal.postalCodeFormControl.invalid">
              {{marketplaceUserVal.getPostalCodeErrorMessage()}}</mat-error>
          </mat-form-field>
          <button mat-icon-button style="color: #003f63" (click)="getAddress()"
            [disabled]="marketplaceUserVal.postalCodeFormControl.invalid">
            <mat-icon>search</mat-icon>
          </button>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15px">
          <mat-form-field class="field">
            <mat-label class="label">Endereço</mat-label>
            <input matInput class="field-line" name="street" [(ngModel)]="marketplaceUser.address.street"
              [formControl]="marketplaceUserVal.streetFormControl">
            <mat-error *ngIf="marketplaceUserVal.streetFormControl.invalid">{{marketplaceUserVal.getStreetErrorMessage()}}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="field" style="width: 60px;">
            <mat-label class="label">Nº</mat-label>
            <input matInput class="field-line" name="number" [(ngModel)]="marketplaceUser.address.number"
              [formControl]="marketplaceUserVal.numberFormControl">
            <mat-error *ngIf="marketplaceUserVal.numberFormControl.invalid">{{marketplaceUserVal.getNumberErrorMessage()}}
            </mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="25px" [ngStyle]="{'display': opened ? 'flex' : 'none' }">
          <mat-form-field class="field">
            <mat-label class="label">Complemento</mat-label>
            <input matInput class="field-line" name="address.complement" [(ngModel)]="marketplaceUser.address.complement">
          </mat-form-field>
          <mat-form-field class="field">
            <mat-label class="label">Bairro</mat-label>
            <input matInput class="field-line" name="address.district" [(ngModel)]="marketplaceUser.address.district"
              [formControl]="marketplaceUserVal.districtFormControl">
            <mat-error *ngIf="marketplaceUserVal.districtFormControl.invalid">
              {{marketplaceUserVal.getDistrictErrorMessage()}}</mat-error>
          </mat-form-field>
        </div>
        <mat-form-field class="field">
          <mat-label class="label">Cidade</mat-label>
          <input matInput class="field-line" name="address.city" [formControl]="marketplaceUserVal.cityFormControl"
            [(ngModel)]="marketplaceUser.address.city">
          <mat-error *ngIf="marketplaceUserVal.cityFormControl.invalid">{{marketplaceUserVal.getCityErrorMessage()}}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label class="label">Estado</mat-label>
          <input matInput class="field-line" name="address.state" [formControl]="marketplaceUserVal.stateFormControl"
            [(ngModel)]="marketplaceUser.address.state">
          <mat-error *ngIf="marketplaceUserVal.stateFormControl.invalid">{{marketplaceUserVal.getStateErrorMessage()}}
          </mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="!hasUser">
        <mat-form-field class="field">
          <mat-label class="label">Nome</mat-label>
          <input matInput class="field-line" name="name" [(ngModel)]="marketplaceUser.name"
            [formControl]="marketplaceUserVal.nameFormControl">
          <mat-error *ngIf="marketplaceUserVal.nameFormControl.invalid">
            {{marketplaceUserVal.getFirstNameErrorMessage()}}
          </mat-error>
        </mat-form-field>
        <div fxLayout="row" fxLayoutGap="25px" [ngStyle]="{'display': opened ? 'flex' : 'none' }">
          <mat-form-field class="field">
            <mat-label class="label">CPF</mat-label>
            <input matInput class="field-line" name="cpf" mask="000.000.000-00" [(ngModel)]="marketplaceUser.cpf"
              [formControl]="marketplaceUserVal.cpfFormControl">
            <mat-error *ngIf="marketplaceUserVal.cpfFormControl.invalid">{{marketplaceUserVal.getCpfErrorMessage()}}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="field">
            <mat-label class="label">Data de Nascimento</mat-label>
            <input matInput class="field-line" type="date" name="birthDate" [(ngModel)]="marketplaceUser.birthDate"
              [formControl]="marketplaceUserVal.birthDateFormControl">
            <mat-error *ngIf="marketplaceUserVal.birthDateFormControl.invalid">
              {{marketplaceUserVal.getBirthDateErrorMessage()}}</mat-error>
          </mat-form-field>
        </div>
        <mat-form-field class="field">
          <mat-label class="label">DDD + Telefone</mat-label>
          <input matInput class="field-line" name="phone" mask="(00) 00000-0000" [(ngModel)]="marketplaceUser.phone"
            [formControl]="marketplaceUserVal.userPhoneFormControl">
          <mat-error *ngIf="marketplaceUserVal.userPhoneFormControl">{{marketplaceUserVal.getUserPhoneErrorMessage()}}
          </mat-error>
        </mat-form-field>
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
          <mat-form-field class="field">
            <mat-label class="label">CEP</mat-label>
            <input matInput class="field-line" name="address.postalCode" mask="00000-000" (keyup)="getAddress()"
              [(ngModel)]="marketplaceUser.address.postalCode" [formControl]="marketplaceUserVal.postalCodeFormControl">
            <mat-error *ngIf="marketplaceUserVal.postalCodeFormControl.invalid">
              {{marketplaceUserVal.getPostalCodeErrorMessage()}}</mat-error>
          </mat-form-field>
          <button mat-icon-button style="color: #003f63" (click)="getAddress()"
            [disabled]="marketplaceUserVal.postalCodeFormControl.invalid">
            <mat-icon>search</mat-icon>
          </button>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15px">
          <mat-form-field class="field">
            <mat-label class="label">Endereço</mat-label>
            <input matInput class="field-line" name="street" [(ngModel)]="marketplaceUser.address.street"
              [formControl]="marketplaceUserVal.streetFormControl">
            <mat-error *ngIf="marketplaceUserVal.streetFormControl.invalid">{{marketplaceUserVal.getStreetErrorMessage()}}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="field" style="width: 60px;">
            <mat-label class="label">Nº</mat-label>
            <input matInput class="field-line" name="number" [(ngModel)]="marketplaceUser.address.number"
              [formControl]="marketplaceUserVal.numberFormControl">
            <mat-error *ngIf="marketplaceUserVal.numberFormControl.invalid">{{marketplaceUserVal.getNumberErrorMessage()}}
            </mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="25px" [ngStyle]="{'display': opened ? 'flex' : 'none' }">
          <mat-form-field class="field">
            <mat-label class="label">Complemento</mat-label>
            <input matInput class="field-line" name="address.complement" [(ngModel)]="marketplaceUser.address.complement">
          </mat-form-field>
          <mat-form-field class="field">
            <mat-label class="label">Bairro</mat-label>
            <input matInput class="field-line" name="address.district" [(ngModel)]="marketplaceUser.address.district"
              [formControl]="marketplaceUserVal.districtFormControl">
            <mat-error *ngIf="marketplaceUserVal.districtFormControl.invalid">
              {{marketplaceUserVal.getDistrictErrorMessage()}}</mat-error>
          </mat-form-field>
        </div>
        <mat-form-field class="field">
          <mat-label class="label">Cidade</mat-label>
          <input matInput class="field-line" name="address.city" [formControl]="marketplaceUserVal.cityFormControl"
            [(ngModel)]="marketplaceUser.address.city">
          <mat-error *ngIf="marketplaceUserVal.cityFormControl.invalid">{{marketplaceUserVal.getCityErrorMessage()}}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label class="label">Estado</mat-label>
          <input matInput class="field-line" name="address.state" [formControl]="marketplaceUserVal.stateFormControl"
            [(ngModel)]="marketplaceUser.address.state">
          <mat-error *ngIf="marketplaceUserVal.stateFormControl.invalid">{{marketplaceUserVal.getStateErrorMessage()}}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="field">
          <mat-label class="label">Password</mat-label>
          <input matInput type="password" class="field-line" name="password" [(ngModel)]="marketplaceUser.password"
            [formControl]="marketplaceUserVal.passwordFormControl">
          <mat-error *ngIf="marketplaceUserVal.passwordFormControl.invalid">
            {{marketplaceUserVal.getPasswordErrorMessage()}}</mat-error>
        </mat-form-field>
      </div>
    </div>
    <!-- </div> -->
  </div>

  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="25px" style="padding-bottom: 25px;">
    <button mat-stroked-button style="color: #003f63" (click)="previousForm()">Voltar</button>
    <button mat-flat-button class="next-button" (click)="userEmailVerify()" (click)="next()"
      style="background-color: #003f63; color: white" >Próximo</button>
  </div>

</div>
