import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AdService {
  public url = environment.urlAuth;
  public image;
  public imageFile;

  constructor(private http: HttpClient) {}

  getAdByMarketplace(marketplaceId) {
    let url = this.url + '/marketplace-ad/' + marketplaceId;
    return this.http.get(url);
  }

  createAd(ad) {
    let url = this.url + '/marketplace-ad/create';
    let imgFile = this.imageFile;
    const formData = new FormData();
    formData.append('image', imgFile);
    formData.append('marketplace', ad.marketplace);
    formData.append('link', ad.link);
    formData.append('active', ad.active);
    // formData.append('num_order', '3');
    console.log(formData);
    return this.http.post(url, formData);
  }

  updateAd(marketplaceId, ad) {
    let url = this.url + '/marketplace-ad/update/' + marketplaceId;
    return this.http.patch(url, ad);
  }
}
