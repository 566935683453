import { Component, OnInit, Inject } from '@angular/core';
import { AdImgCropperService } from '../ad-img-cropper/ad-img-cropper.service';
import { AdService } from '../../_services/ad/ad.service';
import { MarketplaceAd } from '../../interfaces/marketplace-ad';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertService } from '../alert/alert.service';

@Component({
  selector: 'app-create-ad',
  templateUrl: './create-ad.component.html',
  styleUrls: ['./create-ad.component.scss'],
})
export class CreateAdComponent implements OnInit {
  public ad: MarketplaceAd = {};

  constructor(
    private snackbar: MatSnackBar,
    public adImgCropperService: AdImgCropperService,
    public adService: AdService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {}

  fileChangeEvent(event: any): void {
    console.log(event);
    if (event.target.files[0].size < 1000000) {
      this.adImgCropperService.showCropper(event);
    } else {
      // this.alertService.showAlert(
      //   'Erro',
      //   'Imagem muito pesada. \nSó aceitamos imagens menores que 1MB'
      // );
    }
  }

  submit() {
    this.ad.marketplace = this.data;
    if (this.ad.active == undefined) {
      this.ad.active = false;
    }
    this.adService.createAd(this.ad).subscribe((res: any) => {
      if (res.success) {
        this.snackbar.open('Anúncio adicionado com sucesso');
        window.location.reload();
      } else {
        this.alertService.showAlert('Erro', res.message);
      }
    });
  }
}
