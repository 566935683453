import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SignerMenuService {

  public menuOptions = [
    {
      title: 'Marketplace',
      icon: 'storefront',
      navigate: 'signer/marketplace',
      selected: true,
    },
    {
      title: 'Responsável',
      icon: 'person_pin',
      navigate: 'signer/owner',
      selected: false
    },
    {
      title: 'Concluir',
      icon: 'assignment_turned_in',
      navigate: 'signer/check',
      selected: false
    }
  ]

  constructor() { }
}
