<div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="60px" fxFill>
  <div fxLayout="column" fxLayoutAlign="center center">
    <div class="avatar-circle" fxLayout="column" fxLayoutAlign="center center">
      <img style="height: 100%" [src]="marketplaceService.image">
    </div>
    <br><br><br><br><br><br><br><br><br><br><br><br>
  </div>
  <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px" style="width: 50%;">
    <mat-list class="list">
      <mat-list-item>
        <span class="marketplace-name">{{marketplaceService.newMarketplace.name}}</span>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>phone</mat-icon>
        <div mat-line>{{marketplaceService.newMarketplace.phone | phone}}</div>
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item>
        <mat-icon mat-list-icon>person</mat-icon>
        <div mat-line>{{marketplaceService.newMarketplaceUser.name}}
        </div>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>cake</mat-icon>
        <div mat-line>{{marketplaceService.newMarketplaceUser.birthDate | date: 'dd/MM/yyyy'}}</div>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>home</mat-icon>
        <div mat-line>{{marketplaceService.newMarketplaceUser.address.street}},
          {{marketplaceService.newMarketplaceUser.address.number}}
        </div>
        <div mat-line>{{marketplaceService.newMarketplaceUser.address.postalCode}}</div>
        <div mat-line fxLayoutGap="3px">
          <span>
            {{marketplaceService.newMarketplaceUser.address.district}}
          </span>
          <span>•</span>
          <span>
            {{marketplaceService.newMarketplaceUser.address.city}}
          </span>
        </div>
        <div mat-line>{{marketplaceService.newMarketplaceUser.address.state}}</div>
      </mat-list-item>
    </mat-list>
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="25px" style="padding-bottom: 25px;">
      <button mat-stroked-button class="next-button" style="color: #003f63" (click)="previousForm()">Voltar</button>
      <button mat-flat-button style="background-color: #003f63; color: white" (click)="submit()">Concluir</button>
    </div>
  </div>

</div>
