import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Credentials } from '../shared/interfaces/credentials';
import { MarketplaceAuthService } from '../shared/_services/marketplace-auth/marketplace-auth.service';
import { AlertService } from '../shared/dialogs/alert/alert.service';
import { MarketplaceCredentialsValidators } from '../shared/validators/credentials';
import { SocialAuthService, GoogleLoginProvider, SocialUser } from 'angularx-social-login';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public user: Credentials = {};
  public innerWidth;
  public socialUser: SocialUser;
  public googleLogoURL = "https://raw.githubusercontent.com/fireflysemantics/logo/master/Google.svg"; 

  constructor(
    private router: Router,
    private marketplaceAuthService: MarketplaceAuthService,
    private alertService: AlertService,
    public credentialsVal: MarketplaceCredentialsValidators,
    private socialAuthService: SocialAuthService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
    ) {
          this.matIconRegistry.addSvgIcon(
      "logo",
      this.domSanitizer.bypassSecurityTrustResourceUrl(this.googleLogoURL));
    this.innerWidth = window.innerWidth;
  }

  ngOnInit(): void {}

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  sign() {
    if (
      this.credentialsVal.emailFormControl.valid &&
      this.credentialsVal.passwordFormControl.valid
    ) {
      this.marketplaceAuthService.login(this.user).subscribe(
        (res) => {
          if (res.success) {
            this.router.navigate(['']);
          } else {
            this.alertService.showAlert('Erro', res.message);
          }
        },
        (err) => {
          this.alertService.showAlert('Erro', 'Erro ao realizar operação');
        }
      );
    } else {
      this.alertService.showAlert('Atenção', 'Complete os campos corretamente');
    }
  }

  loginGoogle(): void {
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then((res)=>{
      this.marketplaceAuthService.generateSocialLogin(res.response.id_token).subscribe((res: any) =>{
        if (res.success) {
          this.router.navigate(['']);
        } else {
          this.alertService.showAlert('Erro', res.message);
        }
      }, (err) => {
        this.alertService.showAlert('Erro', 'Erro ao realizar operação');
      })
    })
  }
  
  logOutGoogle(): void {
    this.socialAuthService.signOut();
  }
}
