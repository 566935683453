import { ActivatedRoute } from '@angular/router';
import { Component, Inject, Injectable, OnInit } from '@angular/core';
import { MarketplaceService } from 'src/app/shared/_services/marketplace/marketplace.service';
import { AlertService } from 'src/app/shared/dialogs/alert/alert.service';
import { ObjectID } from 'mongodb';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MarketplaceWhatsApp } from '../../shared/interfaces/marketplace-whatsApp';
import { MarketplaceValidators } from 'src/app/shared/validators/marketplace';
import { Integration } from 'src/app/shared/interfaces/integrations';
import { environment } from 'src/environments/environment';

interface List {
    value: string;
    viewValue: string;
}

@Component({
  selector: 'app-modal-edit',
  templateUrl: './modal-edit.component.html',
  styleUrls: ['./modal-edit.component.scss'],
})
@Injectable({
    providedIn: 'root'
})
export class ModalEditComponent implements OnInit{

    marketplaceId: ObjectID | string;
    public marketplace_whatsApp: MarketplaceWhatsApp = {};
    public checkModal = false;
    public editUser = false;
    public editWhats = false;
    public editBot = false;
    public idMarketplace;
    public integrationBody: Integration;
    public urlMarketplace = environment.urlMarketplace
    public title: any;
    public clientMenuLink: any;
    public clientDomain: any;
    public googleTagManagerId: any;

    file: any;

    verticalList: List[] = [
        {value: 'Automotive', viewValue: 'Automotivos'},
        {value: 'Beauty, Spa and Salon', viewValue: 'Beleza, Spa e Salão'},
        {value: 'Clothing and Apparel', viewValue: 'Vestuário'},
        {value: 'Education', viewValue: 'Educação'},
        {value: 'Entertainment', viewValue: 'Entretenimento'},
        {value: 'Event Planning and Service', viewValue: 'Planejamento e serviço de eventos'},
        {value: 'Finance and Banking', viewValue: 'Finanças bancos'},
        {value: 'Food and Grocery', viewValue: 'Comida e mercearia'},
        {value: 'Public Service', viewValue: 'Serviço público'},
        {value: 'Hotel and Lodging', viewValue: 'Hotel e hospedagem'},
        {value: 'Medical and Health', viewValue: 'Medicina e Saúde'},
        {value: 'Non-profit', viewValue: 'Sem fins lucrativos'},
        {value: 'Professional Services', viewValue: 'Serviços profissionais'},
        {value: 'Shopping and Retail', viewValue: 'Compras e Varejo'},
        {value: 'Travel and Transportation', viewValue: 'Viagens e Transportes'},
        {value: 'Restaurant', viewValue: 'Restaurante'},
        {value: 'Other', viewValue: 'Outros'},
    ];

    constructor(
        public marketplaceService: MarketplaceService,
        public marketplaceVal: MarketplaceValidators,
        private alertService: AlertService,
        public dialogRef: MatDialogRef<ModalEditComponent>,
        @Inject(MAT_DIALOG_DATA) data,
    ) {
    }

    ngOnInit(): void {
        console.log('sou o market', this.marketplaceService.marketplaceUser);
        const idMarketplace = window.location.href.split('?')[0].split('/')

        this.idMarketplace = idMarketplace[idMarketplace.length - 1]
        this.toggleModal();
        this.marketplaceVal.emailFormControl.reset();
        this.marketplaceVal.urlFormControl.reset()
        this.integrationBody = {
          token: 'notoken',
          title: this.marketplaceService.marketplaceUser.marketplace.name,
          clientMenuLink: `${this.urlMarketplace}/${this.marketplaceService.marketplaceUser.marketplace.name_id}`,
          clientDomain: '' || this.marketplaceService.marketplaceUser.marketplace.current_dns,
          googleTagManagerId: '' || this.marketplaceService.marketplaceUser.marketplace.current_google_tag_manager_id,
        }

         this.title = this.integrationBody.title;
         this.clientMenuLink = this.integrationBody.clientMenuLink;
         this.clientDomain = 'ex: souzalanches.com';
         this.googleTagManagerId = 'ex: GTM-123456';

          //console.log(this.integrationBody);
    }

    verifyEvent(event){
        if(event == 1){
            this.editUser = true;
            this.editWhats = false;
            this.editBot = false;
            document.getElementById('btn-user').style.color = '#2F6180';
            document.getElementById('btn-whats').style.color = '#afadad';
            document.getElementById('btn-bot').style.color = '#afadad';

        } else if (event == 2){
            this.editWhats = true;
            this.editUser = false;
            this.editBot = false;
            document.getElementById('btn-whats').style.color = '#2F6180';
            document.getElementById('btn-user').style.color = '#afadad';
            document.getElementById('btn-bot').style.color = '#afadad';

        } else if(event == 3){
            this.editBot = true;
            this.editWhats = false;
            this.editUser = false;
            document.getElementById('btn-bot').style.color = '#2F6180';
            document.getElementById('btn-whats').style.color = '#afadad';
            document.getElementById('btn-user').style.color = '#afadad';
        }

    }

    toggleModal() {
        this.checkModal = false;
        this.editUser = true;
        this.editBot = false;
        this.editWhats = false;
        document.querySelector('.modal')?.classList.toggle('modal--hidden');
    };

    closeEdit() {
        this.dialogRef.close();
    };

    uploadButton(event){
    if(event.target.files && event.target.files[0]){
        this.file = event.target.files[0];
    }
    }

    uploadFile(){
        console.log("ENTREI NO UPLOADFILE", this.file, this.marketplaceService.marketplaceUser._id)

        this.marketplaceService
            .WhatsPhotoEdit( this.marketplaceService.marketplaceUser._id, this.file)
            .subscribe(() => {
                this.clearFile();
                this.alertService.showAlert(
                    'Sucesso',
                    "Foto editada com sucesso!"
                );
            });
    }

    clearFile(){
    this.file = "";
    }

    submitEdit(event) {
        const body = {
            marketplace: {
                _id: this.marketplaceService.marketplacePageId,
                name: this.marketplaceService.marketplaceUser.marketplace.name,
                phone: this.marketplaceService.marketplaceUser.marketplace.phone,
                name_id: this.marketplaceService.marketplaceUser.marketplace.name_id,
                config_bot: {
                    bot_name: this.marketplaceService.marketplaceUser.marketplace.config_bot.bot_name,
                    select_using_location: this.marketplaceService.marketplaceUser.marketplace.config_bot.select_using_location,
                },
                production_test: this.marketplaceService.marketplaceUser.marketplace.production_test
            },
            user:{
            name: this.marketplaceService.marketplaceUser.name,
            userPhone: this.marketplaceService.marketplaceUser.phone,
            email: this.marketplaceService.marketplaceUser.email,
            username: this.marketplaceService.marketplaceUser.username,
            _id: this.marketplaceService.marketplaceUser._id
            }
        }

        const whatsApp_User = {
            address: this.marketplace_whatsApp.address,
            description: this.marketplace_whatsApp.description,
            email: this.marketplace_whatsApp.email,
            vertical: this.marketplace_whatsApp.vertical,
            websites: [this.marketplace_whatsApp.websites],
        }

        const WhatsApp_Url = {
            webhooks:{
                url: this.marketplace_whatsApp.webhook_url
            }
        }

        if(this.editUser){
            this.marketplaceService.finishEdit(body).subscribe((res:any) =>{
            if(res.success){
                this.alertService.showAlert(
                    'Sucesso',
                    res.message
                );
                this.closeEdit();
            } else{
                this.alertService.showAlert(
                    'Erro',
                    res.message
                );
                this.closeEdit();
            }
            });
        } else if (this.editWhats){
            if(event == 1){
                this.marketplaceService.WhatsUserEdit(this.marketplaceService.marketplacePageId , whatsApp_User)
                    .subscribe((res:any) =>{
                        if(res.success){
                            this.alertService.showAlert(
                                'Sucesso',
                                'Usuário do whatsapp oficial editado!'
                            );
                        } else{
                            this.alertService.showAlert(
                                'Erro',
                                res.message
                            );
                            this.closeEdit();
                        }
                    },
                    (err)=>{
                        console.log(`erro`, err)
                        this.closeEdit();
                    }
                );
            } else if(event == 2){
                this.marketplaceService.WhatsUrlEdit(this.marketplaceService.marketplacePageId, WhatsApp_Url)
                    .subscribe((res:any) =>{
                        if(res.success){
                            this.alertService.showAlert(
                                'Sucesso',
                                'Url do whatsapp oficial editada!'
                            );
                            this.closeEdit();
                        } else{
                            this.alertService.showAlert(
                                'Erro',
                                res.message
                            );
                            this.closeEdit();
                        }
                    },
                    (err)=>{
                        console.log(`erro`, err)
                        this.closeEdit();
                    }
                );
            }
        }


        if(this.checkModal == false) {
          this.checkModal = true;
          document.querySelector('.modal').classList.toggle('modal--hidden');
        };
      }


      submitEditIntegration() {
        this.marketplaceService.newIntegration(this.idMarketplace, this.integrationBody).subscribe((res:any )=>{
            if(res.success){
                this.alertService.showAlert('Sucesso', 'Editado');
                this.integrationBody = res.info
                this.marketplaceService.marketplaceUser.marketplace.current_dns = this.integrationBody.clientDomain
                this.marketplaceService.marketplaceUser.marketplace.current_google_tag_manager_id = this.integrationBody.googleTagManagerId
                this.closeEdit();
            } else {
                this.alertService.showAlert('Erro',res.message);
                this.closeEdit();
            }
            (err) => {
                console.log('erro',err)
                this.closeEdit();
            }
        })

      }

}
