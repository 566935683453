<!-- <div mat-dialog-title class="div-alert-title">
  <div fxLayout="row" fxLayoutAlign="center center">
    <img class="img" src="../../../../assets/logo-robot.svg">
    <div class="divider"></div>
    <b class="alert-title"><span>Verificação</span></b>
  </div>
</div>
<div mat-dialog-content class="div-alert-content">
  <br>
  <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="25px">
    <div style="width: 260px; text-align: center;"><span class="description" [innerHTML]="description"></span></div>
    <mat-form-field class="field">
      <input matInput class="field-line" name="id" [(ngModel)]="tryWord">
    </mat-form-field>
    <button mat-flat-button flex="33" class="confirm-button" (click)="submit()">Confirmar</button>
  </div>
  <br>
</div>
<div mat-dialog-actions></div> -->
<div mat-dialog-title class="div-alert-title">
  <div fxLayout="row" fxLayoutAlign="center center">
    <img class="img" src="../../../../assets/logo-robot.svg">
    <div class="divider"></div>
    <b class="alert-title"><span>Verificação</span></b>
  </div>
</div>
<div mat-dialog-content class="div-alert-content">
  <br>
  <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="25px">
    <div style="width: 260px; text-align: center;"><span class="description" [innerHTML]="description"></span></div>

    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
      <button mat-flat-button flex="33" (click)="cancel()">Cancelar</button>
      <button mat-flat-button flex="33" style="background-color: #3399ff; color: white"
        (click)="submit()">Confirmar</button>

    </div>

  </div>
  <br>
</div>
<div mat-dialog-actions></div>
