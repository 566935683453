import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AdImgCropperComponent } from './ad-img-cropper.component';

@Injectable({
  providedIn: 'root',
})
export class AdImgCropperService {
  constructor(private dialog: MatDialog) {}

  showCropper(imgFile) {
    this.dialog.open(AdImgCropperComponent, {
      data: {
        imgFile: imgFile,
      },
    });
  }
}
