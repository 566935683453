<mat-sidenav-container class="sidenav-container">
  <mat-sidenav class="sidenav" mode="side" [opened]="opened">
    <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="5px" fxFill>
      <div class="avatar-circle" fxLayout="column" fxLayoutAlign="center center">
        <img style="height: 100%;"
          [src]="marketplaceService.marketplaceUser.marketplace.image || '../../../assets/fastmeal-marketplace-icon.svg'">
      </div>
      <mat-list>
        <mat-list-item>
          <div mat-line class="marketplace-name">{{marketplaceService.marketplaceUser.marketplace.name}}</div>
          <input style="display: none;" id="idmarketplace"
            value="{{marketplaceService.marketplaceUser.marketplace.token}}">
        </mat-list-item>
      </mat-list>
      <div fxLayout="column" fxLayoutAlign="center center" style="width: 100%;">
        <mat-slide-toggle name="active" [(ngModel)]="marketplaceService.marketplaceUser.marketplace.active"
          (change)="changeMarketplaceStatus()">
        </mat-slide-toggle>
      </div>
      <div fxLayout="column" fxLayoutAlign="start start" style="width: 100%;">
        <mat-list>
          <mat-list-item style="color: #2F6180;">
            <mat-icon mat-list-icon>phone</mat-icon>
            <div mat-line>{{marketplaceService.marketplaceUser.marketplace.phone | phone}}</div>
          </mat-list-item>
          <mat-list-item style="color: #2F6180;" *ngIf="marketplaceService.marketplaceUser.marketplace.name_id">
            <mat-icon mat-list-icon>language</mat-icon>
            <div mat-line>
              /{{marketplaceService.marketplaceUser.marketplace.name_id}}</div>
          </mat-list-item>
        </mat-list>
      </div>
      <div class="divider"></div>
      <div fxLayout="column" fxLayoutAlign="start start" style="width: 100% ;">
        <mat-list>
          <mat-list-item style="color: #2F6180;">
            <mat-icon mat-list-icon>person</mat-icon>
            <div mat-line>{{marketplaceService.marketplaceUser.name}}</div>
          </mat-list-item>
          <mat-list-item style="color: #2F6180;">
            <mat-icon mat-list-icon>contact_phone</mat-icon>
            <div mat-line>{{marketplaceService.marketplaceUser.phone}}</div>
          </mat-list-item>
          <mat-list-item style="color: #2F6180;">
            <mat-icon mat-list-icon>mail</mat-icon>
            <div mat-line>{{marketplaceService.marketplaceUser.email}}</div>
          </mat-list-item>
        </mat-list>
        <div class="editBtn">
          <button mat-button class="fab-edit-data" id="editarBtn" (click)="openModal()">
            Editar informação
          </button>
        </div>
      </div>
    </div>
  </mat-sidenav>
  <mat-sidenav-content
    class="infinite-scroll-container"
    infiniteScroll
    [infiniteScrollDistance]="1"
    [infiniteScrollThrottle]="50"
    [scrollWindow]="false"
    (scrolled)="onScroll()">
    <mat-toolbar class="toolbar">
      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px" style="width: 400px; margin-left: 15px;">
        <!-- <span style="color:#003f63">Token</span> -->
        <mat-form-field class="field" matTooltip="Token do marketplace">
          <input matInput read-only class="field-line" name="token"
            [(ngModel)]="marketplaceService.marketplaceUser.marketplace.token">
        </mat-form-field>
        <button mat-icon-button matTooltip="Copiar token do marketplace"
          [cdkCopyToClipboard]="marketplaceService.marketplaceUser.marketplace.token">
          <mat-icon>
            content_copy
          </mat-icon>
        </button>
      </div>
      <span class=" example-spacer"></span>
      <button mat-button style="color: #003f63; font-size: 16px;" (click)="backToHome()">
        Voltar ao menu
      </button>
    </mat-toolbar>
    <div class="panels" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="30px">
      <mat-list class="list">
        <div mat-subheader>
          <span>Páginas Associadas</span>
          <span class=" example-spacer"></span>
          <button *ngIf="!opened" mat-icon-button (click)="linkPage()">
            <mat-icon>add</mat-icon>
          </button></div>
        <mat-list-item *ngFor=" let p of pages">
          <img mat-list-avatar style="border:2px solid #2F6180"
          [src]="p.page_image || '../../../assets/fastmeal-icon.svg'">

          <div mat-line>{{p.page_name}}</div>
          <!-- <mat-slide-toggle name="active" [(ngModel)]="p.pageActive" (change)="changePageStatus(p)"> -->
          <!-- </mat-slide-toggle> -->
          <button mat-mini-fab (click)="synchronize(p.page_id)">
            <mat-icon>sync</mat-icon>
          </button>
          <mat-divider></mat-divider>
        </mat-list-item>
      </mat-list>
      <div *ngIf="marketplaceService.marketplacePages && marketplaceService.marketplacePages.length == 0"
        fxLayout="column" fxLayoutAlign="center center" style="width: 40%;">
        <img src="../../../assets/no-pages-in-marketplace.svg">
      </div>
    </div>
    <button *ngIf="opened" class="fab-add-ad" mat-mini-fab (click)="marketplaceAds()">
      <mat-icon style="font-size: 14px;
              padding-right: 1px;
              padding-top: 6px;">add_comment</mat-icon>
    </button>


    <button *ngIf="opened" class="fab-add-establishments" mat-fab (click)="linkPage()">
      <mat-icon>add</mat-icon>
    </button>
  </mat-sidenav-content>
</mat-sidenav-container>
