import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MarketplaceService } from 'src/app/shared/_services/marketplace/marketplace.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { LinkPageBottomSheetComponent } from 'src/app/shared/components/link-page-bottom-sheet/link-page-bottom-sheet.component';
import { ChangeVerificationService } from 'src/app/shared/dialogs/change-verification/change-verification.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertService } from 'src/app/shared/dialogs/alert/alert.service';
import { ObjectID } from 'mongodb';
import { MarketplacePage } from 'src/app/shared/interfaces/marketplace-page';
import { ModalEditComponent } from 'src/app/modals/madal-edit/modal-edit.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Component({
  selector: 'app-marketplace-details',
  templateUrl: './marketplace-details.component.html',
  styleUrls: ['./marketplace-details.component.scss'],
})
export class MarketplaceDetailsComponent implements OnInit {
  public searchTerm;
  public opened;
  pages: MarketplacePage[] = [];
  page: number = 0;
  marketplaceId: ObjectID | string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public marketplaceService: MarketplaceService,
    private bottomSheet: MatBottomSheet,
    private verificationService: ChangeVerificationService,
    private snackbar: MatSnackBar,
    private alertService: AlertService,
    public modal_edit: ModalEditComponent,
    private matDialog: MatDialog
  ) {
    this.marketplaceId =
      this.marketplaceService.getMarketplaceId() ||
      route.snapshot.paramMap.get('id');

    this.marketplaceService.getMarketplaceDetail(this.marketplaceId);
    this.getPages();
    if (window.innerWidth > 950) {
      this.opened = true;
    } else {
      this.opened = false;
    }
  }

  ngOnInit(): void {}

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (window.innerWidth > 950) {
      this.opened = true;
    } else {
      this.opened = false;
    }
  }

  linkPage() {
    const bSheet = this.bottomSheet.open(LinkPageBottomSheetComponent, {
      data: {
        marketplaceId: this.marketplaceId,
      }
    });

    bSheet.afterDismissed().subscribe((data) => {
      if (data) {
        this.page = 0
        this.pages = []
        this.getPages()
      }
    });
  }

  marketplaceAds() {
    this.router.navigate(['marketplace-ads/' + this.marketplaceId]);
  }

  changePageStatus(page) {
    this.verificationService.showVerificationAlert(
      page.page_name,
      'Tem certeza que você deseja alterar a página ' + page.page_name + '?',
      page.active,
      page._id
    );
  }

  synchronize(page){
    this.marketplaceService.synchronize(page).then((ok) => {
      this.snackbar.open(
        'A página será reatualizada.',
        'Fechar',
        {
          duration: 5000,
        }
      );
    }).catch((err) => {
      console.log(err);
      this.alertService.showAlert('Erro', 'Erro ao realizar operação');
    })
  }

  changeMarketplaceStatus() {
    this.marketplaceService
      .updateMarketplace(
        this.marketplaceId,
        this.marketplaceService.marketplaceUser.marketplace.active
      )
      .subscribe(
        (res: any) => {
          if (res.success) {
            this.snackbar.open(
              'O status do marketplace foi alterado com sucesso!',
              'Fechar',
              {
                duration: 5000,
              }
            );
          } else {
            this.alertService.showAlert(
              'Erro',
              'O status do marketplace não pode ser alterado'
            );
          }
        },
        (err) => {
          this.alertService.showAlert('Erro', 'Erro ao realizar operação');
        }
      );
  }

  // PEDIR PARA O TOPIN MANDAR O PAGE_ACTIVE.
  getPages() {
    this.marketplaceService.getMarketplacePages(this.marketplaceId, ++this.page)
      .subscribe(
        (res: any) => {
          if (res.success) {
            if (res.info && res.info.docs) {
              this.pages = this.pages.concat(res.info.docs as MarketplacePage[]);
              this.marketplaceService.marketplacePages = this.pages;
            }
          } else {
            this.alertService.showAlert(
              'Erro',
              res.message || 'Ocorreu um erro ao tentar buscar as páginas.'
            );
          }
        },
        err => console.log(err)
      );
  }

  onScroll() {
    this.getPages();
  }

  backToHome() {
    this.router.navigate(['']);
  }

  openModal(){
    const dialogRef = this.matDialog.open(ModalEditComponent);
  }

  // openModalConfigPages(page){
  //   const dialogConfig = new MatDialogConfig();
  //   const dialogRef = this.matDialog.open(ModalConfigPageComponent, dialogConfig);
  //   dialogRef.afterClosed().subscribe((res: any)=>{
  //     page.clientDomain = res;
  //     this.marketplaceService.newIntegration(page.page_id, page.clientDomain).subscribe((res: any)=>{
  //       console.log(res);
  //       if(res.success) {
  //         this.alertService.showAlert('Sucesso', 'Operação realizada')
  //         this.getPages()
  //       } else {
  //         this.alertService.showAlert('Erro', 'Erro ao realizar operação')
  //       }
  //     }, (err) => {
  //       this.alertService.showAlert('Erro', 'Erro ao realizar operação')
  //     })
  //   })
  // }

}
