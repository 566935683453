import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import { GoogleLoginProvider } from 'angularx-social-login';

import { SignerComponent } from './dashboard/signer/signer.component';
import { LoginComponent } from './login/login.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { OwnerComponent } from './dashboard/signer/owner/owner.component';
import { MarketplaceComponent } from './dashboard/signer/marketplace/marketplace.component';
import { CheckComponent } from './dashboard/signer/check/check.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ImgCropperComponent } from './shared/dialogs/img-cropper/img-cropper.component';
import { AdImgCropperComponent } from './shared/dialogs/ad-img-cropper/ad-img-cropper.component';
import { AlertComponent } from './shared/dialogs/alert/alert.component';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { MatDialogModule, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtModule } from '@auth0/angular-jwt';
import {
  HTTPListener,
  HTTPStatus,
} from './shared/_services/interceptor/interceptor.service';
const RxJS_Services = [HTTPListener, HTTPStatus];
import { TokenInterceptorService } from './shared/_services/token-interceptor/token-interceptor.service';
import { MainComponent } from './dashboard/main/main.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MarketplaceDetailsComponent } from './dashboard/marketplace-details/marketplace-details.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatGridListModule } from '@angular/material/grid-list';
import { MarketplaceValidators } from './shared/validators/marketplace';
import { MarketplaceUserValidators } from './shared/validators/marketplace-user';
import { MarketplaceCredentialsValidators } from './shared/validators/credentials';
import { LinkPageBottomSheetComponent } from './shared/components/link-page-bottom-sheet/link-page-bottom-sheet.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { ChangeVerificationComponent } from './shared/dialogs/change-verification/change-verification.component';
import { PhonePipe } from './shared/pipes/phone/phone.pipe';
import { CepPipe } from './shared/pipes/cep/cep.pipe';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MarketplaceAdsComponent } from './dashboard/marketplace-ads/marketplace-ads.component';
import { CreateAdComponent } from './shared/dialogs/create-ad/create-ad.component';
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { ModalEditComponent } from './modals/madal-edit/modal-edit.component';
import {MatTabsModule} from '@angular/material/tabs';


export let options: Partial<IConfig> | (() => Partial<IConfig>);
@NgModule({
  declarations: [
    AppComponent,
    SignerComponent,
    LoginComponent,
    OwnerComponent,
    MarketplaceComponent,
    CheckComponent,
    AlertComponent,
    LoaderComponent,
    MainComponent,
    MarketplaceDetailsComponent,
    LinkPageBottomSheetComponent,
    ChangeVerificationComponent,
    PhonePipe,
    CepPipe,
    ImgCropperComponent,
    AdImgCropperComponent,
    MarketplaceAdsComponent,
    CreateAdComponent,
    ModalEditComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    ImageCropperModule,
    MatDialogModule,
    MatTabsModule,
    NgxMaskModule.forRoot(),
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: () => {
          return localStorage.getItem('access_token');
        },
        whitelistedDomains: [
          'https://app.anota.ai',
          'https://staging-app.anota-ai.com/',
        ],
      },
    }),
    InfiniteScrollModule,
    MatToolbarModule,
    MatSelectModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatGridListModule,
    MatBottomSheetModule,
    ClipboardModule,
    HammerModule,
    SocialLoginModule
  ],
  providers: [
    MarketplaceValidators,
    MarketplaceUserValidators,
    MarketplaceCredentialsValidators,
    { provide: MAT_DIALOG_DATA, useValue: {} },
    RxJS_Services,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HTTPListener,
      multi: true,
    },
    {
      provide: MatDialogRef,
      useValue: {}
    },
	  {
      provide: MAT_DIALOG_DATA,
      useValue: []
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider('102097130241-mr30pkrgt7goqpl9fjqg6tl2us7c7jj6.apps.googleusercontent.com'),
          },
        ],
      } as SocialAuthServiceConfig,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule {}
