import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ImgCropperComponent } from './img-cropper.component';

@Injectable({
  providedIn: 'root'
})
export class ImgCropperService {

  constructor(private dialog: MatDialog) { }

  showCropper(imgFile){
    this.dialog.open(ImgCropperComponent, {data: {
      imgFile: imgFile
    }})
  }
}
