<div mat-dialog-title class="div-alert-title">
  <div fxLayout="row" fxLayoutAlign="center center">
    <img class="img" src="../../../../assets/logo-robot.svg">
    <div class="divider"></div>
    <b class="alert-title"><span [innerHTML]="title"></span></b>
  </div>
</div>
<div mat-dialog-content class="div-alert-content">
  <br>
  <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="25px">
    <div style="width: 260px; text-align: center;"><span class="description" [innerHTML]="description"></span></div>
    <button mat-flat-button flex="33" class="cancel-button" (click)="cancel()">Fechar</button>
  </div>
  <br>
</div>
<div mat-dialog-actions></div>