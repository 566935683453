import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { SignerComponent } from './dashboard/signer/signer.component';
import { OwnerComponent } from './dashboard/signer/owner/owner.component';
import { MarketplaceComponent } from './dashboard/signer/marketplace/marketplace.component';
import { CheckComponent } from './dashboard/signer/check/check.component';
import { MarketplaceGuard } from './shared/_guards/marketplace/marketplace.guard';
import { LoginMarketplaceGuard } from './shared/_guards/login-marketplace/login-marketplace.guard';
import { MainComponent } from './dashboard/main/main.component';
import { MarketplaceDetailsComponent } from './dashboard/marketplace-details/marketplace-details.component';
import { MarketplaceAdsComponent } from './dashboard/marketplace-ads/marketplace-ads.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [MarketplaceGuard],
  },
  {
    path: 'marketplace-details/:id',
    component: MarketplaceDetailsComponent,
    canActivate: [MarketplaceGuard],
  },
  {
    path: 'marketplace-ads/:id',
    component: MarketplaceAdsComponent,
    canActivate: [MarketplaceGuard],
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoginMarketplaceGuard],
  },

  {
    path: 'signer',
    component: SignerComponent,
    canActivate: [MarketplaceGuard],
    children: [
      {
        path: 'marketplace',
        component: MarketplaceComponent,
      },
      { path: 'owner', component: OwnerComponent },
      { path: 'check', component: CheckComponent },
      {
        path: '',
        redirectTo: '/signer/marketplace',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
