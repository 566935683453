import { FormControl, Validators, FormGroup } from '@angular/forms';

export class MarketplaceCredentialsValidators {
  emailFormControl = new FormControl('', [Validators.required]);

  passwordFormControl = new FormControl('', [
    Validators.required,
    // Validators.minLength(6),
    // Validators.maxLength(16),
  ]);

  public getEmailErrorMessage() {
    return this.emailFormControl.hasError('required')
      ? 'Este campo é obrigatório'
      : '';
  }

  public getPasswordErrorMessage() {
    return this.passwordFormControl.hasError('required')
      ? 'Este campo é obrigatório'
      : this.passwordFormControl.hasError('minlength')
      ? 'Mínimo 6 caracteres'
      : this.passwordFormControl.hasError('maxlength')
      ? 'Máximo 16 caracteres'
      : '';
  }
}
