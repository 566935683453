<div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px" fxFill style="padding: 20px 30px">

  <div class="image">
    <img style="width: 100%;" [src]="adService.image || '../../../../assets/marketplace-logo-horizontal.svg'">
  </div>
  <button (click)="fileInput.click()" style="background-color: #3399ff;" mat-mini-fab>
    <mat-icon style="color: white">insert_photo</mat-icon>
  </button>
  <input hidden type="file" (change)="fileChangeEvent($event)" #fileInput id="file">

  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px" style="width: 100%;">
    <mat-form-field class="field">
      <mat-label class="label">Url do anúncio</mat-label>
      <input matInput class="field-line" name="id" [(ngModel)]="ad.link">
    </mat-form-field>

    <mat-slide-toggle name="active" matTooltip="Status da página" [(ngModel)]="ad.active">
    </mat-slide-toggle>
  </div>


  <button mat-button style="background-color: #003f63; color: white" (click)="submit()">
    Adicionar Anúncio
  </button>
</div>
