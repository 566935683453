import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdService } from '../../shared/_services/ad/ad.service';
import { MatDialog } from '@angular/material/dialog';
import { CreateAdComponent } from '../../shared/dialogs/create-ad/create-ad.component';

@Component({
  selector: 'app-marketplace-ads',
  templateUrl: './marketplace-ads.component.html',
  styleUrls: ['./marketplace-ads.component.scss'],
})
export class MarketplaceAdsComponent implements OnInit {
  public marketplaceId;
  public ads;

  constructor(
    private route: ActivatedRoute,
    private adService: AdService,
    private router: Router,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.marketplaceId = this.route.snapshot.paramMap.get('id');
    this.getAds();
  }

  backToMarketplace() {
    this.router.navigate(['/marketplace-details/' + this.marketplaceId]);
  }

  getAds() {
    this.adService
      .getAdByMarketplace(this.marketplaceId)
      .subscribe((res: any) => {
        this.ads = res.info;
      });
  }

  createAd() {
    this.dialog.open(CreateAdComponent, {
      data: this.marketplaceId,
    });
  }

  goToUrl(url: string) {
    window.open('https://' + url);
  }

  updateStatus(ad) {

    this.adService.updateAd(ad._id, { active: ad.active }).subscribe((res) => {
    });
  }
}
