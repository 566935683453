import { Component, OnInit, HostListener } from '@angular/core';
import { MarketplaceValidators } from 'src/app/shared/validators/marketplace';
import { Marketplace } from 'src/app/shared/interfaces/marketplace';
import { AlertService } from 'src/app/shared/dialogs/alert/alert.service';
import { SignerMenuService } from '../signer-menu.service';
import { Router } from '@angular/router';
import { MarketplaceService } from 'src/app/shared/_services/marketplace/marketplace.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ImgCropperService } from 'src/app/shared/dialogs/img-cropper/img-cropper.service';

@Component({
  selector: 'app-establishment',
  templateUrl: './marketplace.component.html',
  styleUrls: ['./marketplace.component.scss'],
})
export class MarketplaceComponent implements OnInit {
  public marketplace: Marketplace = {};
  public opened;

  constructor(
    private signerMenuService: SignerMenuService,
    private router: Router,
    public marketplaceVal: MarketplaceValidators,
    public alertService: AlertService,
    public marketplaceService: MarketplaceService,
    private snackbar: MatSnackBar,
    public imgCropperService: ImgCropperService
  ) {
    this.marketplace.colors = {};
    if (window.innerWidth > 950) {
      this.opened = true;
    } else {
      this.opened = false;
    }
  }

  ngOnInit(): void {
    this.marketplaceVal.nameFormControl.reset();
    this.marketplaceVal.phoneFormControl.reset();
    this.marketplaceVal.nameIdFormControl.reset();
    this.marketplaceVal.primaryColorFormControl.reset();
    this.marketplaceVal.secondaryColorFormControl.reset();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (window.innerWidth > 950) {
      this.opened = true;
    } else {
      this.opened = false;
    }
    console.log(this.opened);
    // this.innerWidth = window.innerWidth;
  }

  fileChangeEvent(event: any): void {
    console.log(event);
    if (event.target.files[0].size < 1000000) {
      this.imgCropperService.showCropper(event);
    } else {
      this.alertService.showAlert(
        'Erro',
        'Imagem muito pesada. \nSó aceitamos imagens menores que 1MB'
      );
    }
  }

  cancel() {
    this.router.navigate(['']);
  }

  next() {
    if (
      this.marketplaceVal.nameFormControl.invalid ||
      this.marketplaceVal.phoneFormControl.invalid ||
      this.marketplaceVal.nameIdFormControl.invalid||
      this.marketplaceVal.primaryColorFormControl.invalid ||
      this.marketplaceVal.secondaryColorFormControl.invalid
    ) {
      this.snackbar.open('Complete os campos corretamente', 'Fechar', {
        duration: 3000,
      });
    } else {
      for (let menuOption of this.signerMenuService.menuOptions) {
        if (menuOption.navigate != 'signer/owner') {
          menuOption.selected = false;
        } else {
          menuOption.selected = true;
        }
      }
      this.marketplaceService.newMarketplace = this.marketplace;
      this.router.navigate(['signer/owner']);
    }
  }

  backToHome() {
    this.router.navigate(['']);
  }
}
