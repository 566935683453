import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AlertService } from '../../dialogs/alert/alert.service';
import { tap, catchError } from "rxjs/operators";
import { JwtHelperService } from '@auth0/angular-jwt';


const TOKEN_KEY = "access_token";

@Injectable({
  providedIn: 'root'
})
export class MarketplaceAuthService {
  public url = environment.urlNoAuth + "/authenticate/login";
  public generateUrl = `${environment.urlNoAuth}/google-auth/generate-token`
  public marketplaceAuthenticationState: boolean = false;
  private marketplaceId;
  private _token;

  constructor(
    private http: HttpClient,
    private helper: JwtHelperService,
    public alertService: AlertService
  ) {}

  ngOnInit() {}

  getJwtToken(): any {
    if (this._token == undefined || this._token == "") {
      this._token = localStorage.getItem(TOKEN_KEY);
    }
    return this._token;
  }

  checkToken(): Promise<boolean> {
    return new Promise((resolve) => {
      var token = localStorage.getItem(TOKEN_KEY);
      if (token) {
        let decoded = this.helper.decodeToken(token);
        let isExpired = this.helper.isTokenExpired(token);
        if (!isExpired) {
          this.marketplaceAuthenticationState = true;
          this.marketplaceId = decoded;
          resolve(this.marketplaceAuthenticationState);
        } else {
          this.marketplaceAuthenticationState = false;
          localStorage.removeItem(TOKEN_KEY);
          resolve(this.marketplaceAuthenticationState);
        }
      } else {
        this.marketplaceAuthenticationState = false;
        resolve(this.marketplaceAuthenticationState);
      }
    });
  }

  login(credentials) {
    return this.http.post(this.url, credentials).pipe(
      tap((res: any) => {
        if (res.success) {
          this._token = res["access_token"];
          localStorage.setItem(TOKEN_KEY, res["access_token"]);
          console.log(localStorage.getItem(TOKEN_KEY))
          this.marketplaceId = this.helper.decodeToken(res["access_token"]);
          this.marketplaceAuthenticationState = true;
        } else {
          this.alertService.showAlert("Erro", res.message);
        }
      }),
      catchError((err) => {
        throw new Error();
      })
    );
  }

  logout() {
    localStorage.removeItem(TOKEN_KEY);
    this.marketplaceAuthenticationState = false;
  }

  isAuthenticated(): Promise<boolean> {
    return this.checkToken();
  }

  generateSocialLogin(token){
    return this.http.post(`${this.generateUrl}?code=${token}`, {}).pipe(
      tap((res: any)=>{
        this._token = res.data.token;
        localStorage.setItem(TOKEN_KEY, res.data.token);
        console.log(localStorage.getItem(TOKEN_KEY))
        this.marketplaceId = this.helper.decodeToken(res.data.token);
        this.marketplaceAuthenticationState = true;
      })
    );
  }
}
